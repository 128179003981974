import { createStore } from 'vuex';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default createStore({
    state() {
        return {
            user: null,
            isAppLoaded: false,
            ingredients: [],
            ingredientGroups: [],
            categories: [],
            recipes: [],
            authors: [],
            isLoading: false,
            notifications: [],
            stats: [],
            search: []
        };
    },
    mutations,
    actions,
    getters,
});