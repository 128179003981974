export const API_URL = {
	production: 'https://api.cclqt.com/',
	development: 'https://dev.api.cclqt.com/'
};

export const S3_URL = {
	production: 'https://cocinaconloquetienes-assets.s3.eu-west-1.amazonaws.com/',
	development: 'https://cocinaconloquetienes-dev-assets.s3.eu-west-1.amazonaws.com/'
};

export const NOTIFICATION_TIMEOUT = 5000;

export const NOTIFICATION_TYPES = {
	ERROR: 1,
	SUCCESS: 2,
};

export const RECIPE_DIFFICULTIES = {
	EASY: 1,
	MEDIUM: 2,
	HARD: 3
};

export const INGREDIENT_TYPES = {
	PRIMARY: 1,
	SECONDARY: 2,
	TERTIARY: 3,
};

export const SORT_ORDERS = {
	ASC: 1,
	DESC: 2
};

export const INGREDIENT_SORT_TYPES = {
	BY_NAME: 1,
	BY_NUMBER_OF_RECIPES: 2,
	BY_CREATION_DATE: 3,
	BY_UPDATE_DATE: 4,
	BY_GROUP: 5,
};

export const CATEGORY_SORT_TYPES = {
	BY_NAME: 1,
	BY_NUMBER_OF_RECIPES: 2,
	BY_CREATION_DATE: 3,
	BY_UPDATE_DATE: 4,
	BY_VISITED: 5,
};

export const RECIPE_SORT_TYPES = {
	BY_TITLE: 1,
	BY_TIME: 2,
	BY_PEOPLE: 3,
	BY_RATING: 4,
	BY_NUMBER_OF_INGREDIENTS: 5,
	BY_NUMBER_OF_CATEGORIES: 6,
	BY_CREATION_DATE: 7,
	BY_UPDATE_DATE: 8,
	BY_VISITED: 9,
	BY_SHARED: 10,
	BY_FAVORITES: 11,
};

export const CONFIG_CONSTANTS = {
	FLAT_LIST_AD_START: {
		text: 'Nº de recetas antes del primer anuncio en listados',
		type: 'number',
		min: 0,
		max: 20,
	},
	FLAT_LIST_AD_INTERVAL: {
		text: 'Nº de recetas entre anuncios en listados',
		type: 'number',
		min: 1,
		max: 20,
	},
	INTERSTITIAL_AD_INTERVAL: {
		text: 'Nº de búsquedas para volver a mostrar anuncio a pantalla completa',
		type: 'number',
		min: 1,
		max: 20,
	},
	NOVELTY_MAX_DAYS: {
		text: 'Nº de días para considerar una receta como novedad',
		type: 'number',
		min: 1,
		max: 180,
	},
	SHOW_RECIPE_LIST_ADS: {
		text: '¿Mostrar anuncios en listados?',
		type: 'boolean',
	},
	SHOW_RECIPE_ADS: {
		text: '¿Mostrar anuncios en recetas?',
		type: 'boolean',
	},
	SHOW_INTERSTITIAL_ADS: {
		text: '¿Mostrar anuncios a pantalla completa?',
		type: 'boolean',
	},
	SHOW_ADS: {
		text: '¿Mostrar anuncios?',
		type: 'boolean',
	},
};

export const STAT_TYPES = {
	INGREDIENTS_SEARCH: {
		value: 1,
		text: 'Búsqueda',
	},
    RECIPE_VIEW: {
		value: 2,
		text: 'Receta visitada',
	},
    RECIPE_FAVORITE: {
		value: 3,
		text: 'Receta favorita',
	},
    RECIPE_SHARE: {
		value: 4,
		text: 'Receta compartida',
	},
    CATEGORY_VIEW: {
		value: 5,
		text: 'Categoría visitada',
	}
};