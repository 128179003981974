import { createRouter, createWebHashHistory } from 'vue-router';

import Home from "./components/Home.vue";
import Search from "./components/Search.vue";
import IngredientsList from "./components/IngredientsList.vue";
import CategoriesList from "./components/CategoriesList.vue";
import RecipesList from "./components/RecipesList.vue";
import Recipe from "./components/Recipe.vue";
import RecipeEdit from "./components/RecipeEdit.vue";
import Config from "./components/Config.vue";

const routes = [{
    path: '/',
    components: { Home },
}, {
    path: '/search',
    components: { Search },
}, {
    path: '/recipes',
    components: { RecipesList },
}, {
    path: '/categories',
    components: { CategoriesList },
}, {
    path: '/ingredients',
    components: { IngredientsList },
}, {
    path: '/recipe/:recipeId',
    components: { Recipe },
    props: true,
}, {
    path: '/recipe/:recipeId/edit',
    components: { RecipeEdit },
    props: true,
}, {
    path: '/recipe/new',
    components: { RecipeEdit },
}, {
    path: '/config',
    components: { Config },
}];

export default createRouter({
    history: createWebHashHistory(),
    routes,
});