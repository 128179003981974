<template>
  <div class="modal">
    <div class="modal-header">
      <slot name="header"></slot>
      <font-awesome-icon
        class="close-icon"
        @click="onClose"
        :icon="['fas', 'times']"
      />
    </div>
    <div class="modal-body">
      <slot name="body"></slot>
    </div>
    <div class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ["onCloseModal"],
  methods: {
    onClose() {
      this.$emit("onCloseModal");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.modal-header {
  font-size: 1.5rem;
  padding: 1rem;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.modal-body {
  padding: 1rem;
}
.modal-footer {
  padding: 1rem;
  border-top: 1px solid #dcdcdc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  cursor: pointer;
}
</style>