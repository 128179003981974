<template>
  <div>
    <div class="header">
      <input
        class="input"
        type="text"
        v-model="filter"
        @keyup="onDebouncedType"
        placeholder="Filtrar categorías..."
      />
      <span class="total">{{
        guessPlural(totalCategories, "categoría", "categorías")
      }}</span>
      <label class="label" for="sortType">Ordenar por</label>
      <select id="sortType" @change="onSort" class="select" v-model="sortType">
        <option :value="categorySortTypes.BY_NAME">Nombre</option>
        <option :value="categorySortTypes.BY_NUMBER_OF_RECIPES">
          Nº de recetas
        </option>
        <option :value="categorySortTypes.BY_CREATION_DATE">
          Fecha de creación
        </option>
        <option :value="categorySortTypes.BY_UPDATE_DATE">
          Fecha de modificación
        </option>
        <option :value="categorySortTypes.BY_VISITED">Visitadas</option>
      </select>
      <select
        id="sortOrder"
        @change="onSort"
        class="select"
        v-model="sortOrder"
      >
        <option :value="sortOrders.ASC">Ascendente</option>
        <option :value="sortOrders.DESC">Descendente</option>
      </select>
    </div>
    <div class="category-list">
      <div
        class="category-list-item"
        v-for="category in filteredCategories"
        :key="category._id"
        :title="`Creada: ${getFormattedDate(category.createdAt)} ${
          category.createdBy || ''
        }\nModificada: ${getFormattedDate(category.updatedAt)} ${
          category.updatedBy || ''
        }`"
      >
        <div class="category-data">
          <div class="name">{{ category.name }}</div>
          <div class="category-extra">
            <div @click="onClickRecipes(category)" class="category-extra-total">
              {{ guessPlural(category.recipes.length, "receta", "recetas") }}
            </div>
            <div class="category-options">
              <div class="category-options-normal">
                <font-awesome-icon
                  :icon="['fas', 'hand-pointer']"
                  class="category-options-normal-icon"
                />
                <span>{{ category.visited }}</span>
              </div>
              <div class="category-options-hover">
                <font-awesome-icon
                  @click="onEditCategory(category)"
                  :icon="['fas', 'edit']"
                  class="category-options-hover-icon"
                />
                <font-awesome-icon
                  @click="onPreDeleteCategory(category)"
                  :icon="['fas', 'times']"
                  class="category-options-hover-icon"
                />
              </div>
            </div>
          </div>
        </div>
        <img :src="getImageURL('categories', category.image)" />
      </div>
    </div>
    <Popup
      v-if="edition.show"
      v-on:onClosePopup="onCancelEditionPopup"
      headerText="Editar categoría"
    >
      <template v-slot:body>
        <div class="popup category-popup">
          <input class="popup-input" type="text" v-model.trim="edition.name" />
          <Button
            class="category-popup-change-image"
            v-on:onClickButton="onChangeImage"
            text="Cambiar imagen"
          />
          <img
            v-if="!edition.imageData"
            :src="getImageURL('categories', edition.image)"
          />
          <img v-if="edition.imageData" :src="edition.imageData.dataURL" />
        </div>
      </template>
      <template v-slot:footer>
        <Button
          class="popup-button"
          v-on:onClickButton="onCancelEditionPopup"
          text="Cancelar"
        />
        <Button
          class="popup-button"
          type="save"
          v-on:onClickButton="onSaveCategory"
          text="Guardar"
        />
      </template>
    </Popup>
    <Popup
      v-if="recipes.show"
      v-on:onClosePopup="onCancelRecipesPopup"
      :footer="false"
      :headerText="`Recetas con categoría '${recipes.category.name}'`"
    >
      <template v-slot:body>
        <div class="popup">
          <ul>
            <li
              @click="onClickRecipe(recipe)"
              v-for="recipe in recipes.category.recipes"
              :key="recipe._id"
            >
              {{ recipe.title }}
            </li>
          </ul>
        </div>
      </template>
    </Popup>
    <Modal v-if="showImageCropper" v-on:onCloseModal="onCancelCropper">
      <template v-slot:header>Cambiar imagen</template>
      <template v-slot:body>
        <Cropper v-on:onCrop="onCropImage" />
      </template>
      <template v-slot:footer>
        <Button
          class="cropper-button"
          v-on:onClickButton="onCancelCropper"
          text="Cancelar"
        />
        <Button
          class="cropper-button"
          type="save"
          v-on:onClickButton="onSaveCropper"
          text="Guardar"
        />
      </template>
    </Modal>
    <PopupDelete
      v-if="showDeletePopup"
      v-on:onClosePopup="onCancelDeletePopup"
      v-on:onDelete="onDeleteCategory"
      headerText="Eliminar categoría"
      :bodyText="`¿Deseas eliminar la categoría '${deleteCategory.name}'?`"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import {
  guessPlural,
  getImageURL,
  createImageName,
  getFormattedDate,
} from "../util/utils";
import { DELETE_CATEGORY, UPDATE_CATEGORY } from "../store/actions";
import { CATEGORY_SORT_TYPES, SORT_ORDERS } from "../util/constants";

export default {
  name: "CategoriesList",
  data() {
    return {
      filteredCategories: this.$store.state.categories,
      filter: "",
      sortType: CATEGORY_SORT_TYPES.BY_NAME,
      sortOrder: SORT_ORDERS.ASC,
      showImageCropper: false,
      showDeletePopup: false,
      deleteCategory: null,
      edition: {
        show: false,
        id: null,
        name: null,
        image: null,
        imageData: null,
      },
      recipes: {
        show: false,
        category: {},
      },
    };
  },
  computed: {
    totalCategories() {
      return this.filteredCategories.length;
    },
    categorySortTypes() {
      return CATEGORY_SORT_TYPES;
    },
    sortOrders() {
      return SORT_ORDERS;
    },
    ...mapState(["categories", "user"]),
    ...mapGetters(["filterCategories", "sortCategories"]),
  },
  methods: {
    guessPlural,
    getImageURL,
    getFormattedDate,
    onType() {
      this.filteredCategories = this.filterCategories(this.filter);
    },
    onSort() {
      this.filteredCategories = this.sortCategories(
        this.filteredCategories,
        this.sortType,
        this.sortOrder
      );
    },
    onPreDeleteCategory(category) {
      this.showDeletePopup = true;
      this.deleteCategory = category;
    },
    onCancelDeletePopup() {
      this.showDeletePopup = false;
      this.deleteCategory = null;
    },
    onDeleteCategory() {
      this.$store.dispatch(DELETE_CATEGORY, { category: this.deleteCategory });
      this.showDeletePopup = false;
      this.deleteCategory = null;
    },
    onEditCategory(category) {
      this.edition.show = true;
      this.edition.id = category._id;
      this.edition.name = category.name;
      this.edition.image = category.image;
    },
    onCancelEditionPopup() {
      this.edition.show = false;
      this.edition.id = null;
      this.edition.name = null;
      this.edition.image = null;
    },
    onChangeImage() {
      this.showImageCropper = true;
    },
    onCancelCropper() {
      this.showImageCropper = false;
      this.edition.imageData = null;
    },
    onCropImage({ dataURL, blob }) {
      this.edition.imageData = {
        dataURL,
        blob,
      };
    },
    onSaveCropper() {
      this.showImageCropper = false;
    },
    onSaveCategory() {
      this.edition.show = false;
      const category = {
        _id: this.edition.id,
        name: this.edition.name,
        image: createImageName(this.edition.name),
        updatedBy: this.user.username,
      };
      this.$store.dispatch(UPDATE_CATEGORY, {
        category,
        imageData: this.edition.imageData,
      });
    },
    onCancelRecipesPopup() {
      this.recipes = {
        show: false,
        category: {},
      };
    },
    onClickRecipes(category) {
      this.recipes = {
        show: true,
        category,
      };
    },
    onClickRecipe(recipe) {
      this.$router.push("/recipe/" + recipe._id);
    },
  },
  created() {
    // Debouncing with Lodash
    this.onDebouncedType = _.debounce(this.onType, 300);
  },
  unmounted() {
    // Cancel the timer when the component is removed
    this.onDebouncedType.cancel();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
}
.header .input {
  padding: 0.5rem 1em;
  border: 1px solid #eaecef;
  font-size: 1.1rem;
  outline: none;
}
.header .select {
  margin-left: 0.5rem;
  padding: 0.5rem 1em;
  font-size: 1.1rem;
  border: 1px solid #d4d9e0;
  outline: none;
  color: #2c3e50;
}
.header .label {
  margin-left: 5rem;
  font-size: 1.1rem;
  color: #2c3e50;
}
.header .total {
  font-size: 1.1rem;
  margin-left: 2rem;
  color: #949494;
  font-style: italic;
}
.header input::placeholder {
  color: #afafaf;
}
.category-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.category-list-item {
  width: 18rem;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}
.category-data {
  padding: 0.5rem;
  box-shadow: 0px 0px 10px 1px #dcdcdc;
}
.category-list-item img {
  width: 100%;
  box-shadow: 0px 0px 10px 1px #dcdcdc;
}
.category-list-item:hover .category-data {
  box-shadow: 0px 0px 15px 2px #afafaf;
}
.category-list-item:hover img {
  box-shadow: 0px 0px 15px 2px #afafaf;
}
.category-list-item .name {
  font-size: 1.3rem;
}
.category-options {
  margin-left: 2rem;
}
.category-options-normal {
  display: block;
}
.category-options-hover {
  display: none;
}
.category-list-item:hover .category-options-hover {
  display: block;
}
.category-list-item:hover .category-options-normal {
  display: none;
}
.category-options-normal-icon {
  font-size: 0.9rem;
  padding-right: 0.5rem;
}
.category-options-hover-icon {
  font-size: 1.3rem;
  color: #a11f1f;
  padding-right: 0.5rem;
  cursor: pointer;
}
.category-extra {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 2rem;
}
.category-extra-total {
  font-style: italic;
  color: #8c8c8c;
  cursor: pointer;
}
.category-extra-total:hover {
  text-decoration: underline;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-icon {
  color: #2e4f98;
  margin-right: 0.7rem;
  font-size: 1.5rem;
}
.popup-title-category {
  font-style: italic;
  margin-left: 0.3rem;
  font-weight: bold;
}
.popup ul {
  margin: 0;
  padding: 0;
}
.popup ul li {
  padding: 0.5rem;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.popup ul li:hover {
  background-color: rgb(244, 244, 244);
}
.popup.category-popup {
  align-items: center;
  width: 20rem;
}
.popup .category-popup-change-image {
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.popup.category-popup img {
  width: 100%;
}
.popup-input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  width: 100%;
  border: 1px solid #d4d9e0;
}
.popup-button {
  margin-left: 1rem;
}
</style>
