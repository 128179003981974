<template>
  <span :class="['button', type, disabled]" @click="onClick">
    <font-awesome-icon v-if="icon" :icon="['fas', icon]" class="icon" />
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "Button",
  emits: ["onClickButton"],
  props: {
    type: String,
    text: String,
    to: String,
    disabled: Boolean,
    icon: String
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit("onClickButton");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(237, 237, 237);
  background-color: rgb(255, 255, 255);
}
.button .icon {
  margin-right: 0.3rem;
}
.button.new {
  background-color: rgb(40, 155, 63);
  color: #fff;
}
.button.edit {
  background-color: rgb(52, 82, 179);
  color: #fff;
}
.button.delete {
  background-color: rgb(179, 52, 52);
  color: #fff;
}
.button.save {
  background-color: rgb(40, 155, 63);
  color: #fff;
}
.button.disabled {
  background-color: rgb(116, 116, 116);
  color: #fff;
}
.button.generate {
  background-color: #a21f1f;
  color: #fff;
  padding: .3rem .5rem;
  font-weight: 500;
  margin-left: 1rem;
}
</style>
