<template>
  <div>
    <div class="subtitle">Totales</div>
    <div class="header">
      <HomeKPI title="Recetas" :value="recipes.length" />
      <HomeKPI title="Categorías" :value="categories.length" />
      <HomeKPI title="Ingredientes" :value="ingredients.length" />
      <HomeKPI title="Autores" :value="authors.length" />
    </div>
    <br />
    <div class="refresh-wrapper">
      <div class="subtitle">Última actividad</div>
      <font-awesome-icon
        @click="onRefreshStats"
        :icon="['fas', 'sync']"
        class="refresh"
      />
    </div>
    <HomeStats />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { REFRESH_STATS } from '../store/actions';
import HomeKPI from "./HomeKPI";
import HomeStats from "./HomeStats";

export default {
  name: "Home",
  components: {
    HomeKPI,
    HomeStats,
  },
  methods: {
    onRefreshStats() {
      this.$store.dispatch(REFRESH_STATS);
    },
  },
  computed: mapState(["ingredients", "categories", "recipes", "authors"]),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.refresh-wrapper {
  display: flex;
  align-items: baseline;
}
.subtitle {
  font-size: 2rem;
  padding: 1rem 0.5rem;
}
.refresh {
  margin-left: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
