<template>
  <div class="kpi">
    <span class="value">{{ value }}</span>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "HomeKPI",
  props: {
    value: Number,
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.kpi {
  background-color: #fff;
  margin: 0.5rem 1rem;
  padding: 1rem;
  border: 1px solid #eaecef;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13rem;
}
.kpi .value {
  font-size: 2.5rem;
}
.kpi .title {
  font-size: 1rem;
}
</style>
