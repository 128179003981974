<template>
  <div>
    <div class="header">
      <input
        class="input"
        type="text"
        v-model="search"
        placeholder="Filtrar ingredientes..."
      />
      <input
        class="check"
        type="checkbox"
        id="primaryCheck"
        v-model="primaryCheck"
      />
      <label class="label-check" for="primaryCheck">Primarios</label>
      <input
        class="check"
        type="checkbox"
        id="secondaryCheck"
        v-model="secondaryCheck"
      />
      <label class="label-check" for="secondaryCheck">Secundarios</label>
      <input
        class="check"
        type="checkbox"
        id="tertiaryCheck"
        v-model="tertiaryCheck"
      />
      <label class="label-check" for="tertiaryCheck">Terciarios</label>
      <input
        class="check"
        type="checkbox"
        id="groupCheck"
        v-model="groupCheck"
      />
      <label class="label-check" for="groupCheck">Con grupo</label>
      <span class="total">{{
        guessPlural(totalIngredients, "ingrediente", "ingredientes")
      }}</span>
      <label class="label-select" for="sortType">Ordenar por</label>
      <select id="sortType" @change="onSort" class="select" v-model="sortType">
        <option :value="ingredientSortTypes.BY_NAME">Nombre</option>
        <option :value="ingredientSortTypes.BY_NUMBER_OF_RECIPES">
          Nº de recetas
        </option>
        <option :value="ingredientSortTypes.BY_CREATION_DATE">
          Fecha de creación
        </option>
        <option :value="ingredientSortTypes.BY_UPDATE_DATE">
          Fecha de modificación
        </option>
        <option :value="ingredientSortTypes.BY_GROUP">
          Grupo
        </option>
      </select>
      <select
        id="sortOrder"
        @change="onSort"
        class="select"
        v-model="sortOrder"
      >
        <option :value="sortOrders.ASC">Ascendente</option>
        <option :value="sortOrders.DESC">Descendente</option>
      </select>
    </div>
    <ul class="ingredient-list">
      <li
        class="ingredient-list-item"
        v-for="ingredient in filteredIngredients"
        :key="ingredient._id"
      >
        <div class="ingredient">
          <div :class="['ingredient-icon', 'icon-' + ingredient.type]">
            <div v-if="ingredient.type === 1" class="icon">P</div>
            <div v-if="ingredient.type === 2" class="icon">S</div>
            <div v-if="ingredient.type === 3" class="icon">T</div>
          </div>
          <div class="ingredient-text">
            <span class="name">{{ ingredient.name }}</span>
          </div>
          <div v-if="ingredient.group" class="ingredient-group">
            <span class="name">{{ ingredient.group }}</span>
          </div>
          <div @click="onClickRecipes(ingredient)" class="ingredient-stats">
            {{ guessPlural(ingredient.recipes.length, "receta", "recetas") }}
          </div>
        </div>
        <div class="ingredient-options">
          <font-awesome-icon
            @click="onEditIngredient(ingredient)"
            :icon="['fas', 'edit']"
            class="ingredient-options-icon"
          />
          <font-awesome-icon
            @click="onPreDeleteIngredient(ingredient)"
            :icon="['fas', 'times']"
            class="ingredient-options-icon"
          />
          <span class="date">{{
            `Creado: ${getFormattedDate(ingredient.createdAt)} ${
              ingredient.createdBy || ""
            }`
          }}</span>
          <span class="date">{{
            `Modificado: ${getFormattedDate(ingredient.updatedAt)} ${
              ingredient.updatedBy || ""
            }`
          }}</span>
        </div>
      </li>
    </ul>
    <Popup
      v-if="edition.show"
      v-on:onClosePopup="onCancelEditionPopup"
      headerText="Editar ingrediente"
    >
      <template v-slot:body>
        <div class="popup ingredient-popup">
          <div class="popup-subtitle">Nombre</div>
          <input class="popup-input" type="text" v-model.trim="edition.name" />
          <div class="popup-subtitle">Tipo</div>
          <div class="popup-radio">
            <div class="popup-radio-item">
              <input
                class="input-radio"
                type="radio"
                id="type1"
                value="1"
                v-model="edition.type"
              />
              <label for="type1">Principal</label>
            </div>
            <div class="popup-radio-item">
              <input
                class="input-radio"
                type="radio"
                id="type2"
                value="2"
                v-model="edition.type"
              />
              <label for="type2">Secundario</label>
            </div>
            <div class="popup-radio-item">
              <input
                class="input-radio"
                type="radio"
                id="type3"
                value="3"
                v-model="edition.type"
              />
              <label for="type3">Terciario</label>
            </div>
          </div>
          <div class="popup-subtitle">Grupo</div>
          <div class="popup-group">
            <input class="popup-input" type="text" v-model.trim="groupSearch" />
            <div
              v-if="
                groupSearch &&
                ((filteredGroups.length && groupSearch !== edition.group) ||
                  filteredGroups.length === 0)
              "
              class="filtered-groups"
            >
              <ul v-if="filteredGroups.length">
                <li
                  @click="onAddGroup(group)"
                  v-for="group in filteredGroups"
                  :key="group"
                >
                  {{ group }}
                </li>
              </ul>
              <div class="group-new" v-if="filteredGroups.length === 0">
                Nuevo grupo <b>{{ groupSearch }}</b>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <Button
          class="popup-button"
          v-on:onClickButton="onCancelEditionPopup"
          text="Cancelar"
        />
        <Button
          class="popup-button"
          type="save"
          v-on:onClickButton="onSaveIngredient"
          text="Guardar"
        />
      </template>
    </Popup>
    <Popup
      v-if="recipes.show"
      v-on:onClosePopup="onCancelRecipesPopup"
      :footer="false"
      :headerText="`Recetas con '${recipes.ingredient.name}'`"
    >
      <template v-slot:body>
        <div class="popup">
          <ul>
            <li
              @click="onClickRecipe(recipe)"
              v-for="recipe in recipes.ingredient.recipes"
              :key="recipe._id"
            >
              {{ recipe.title }}
            </li>
            <p v-if="recipes.ingredient.recipes.length === 0">
              No existen recetas con el ingrediente
              {{ recipes.ingredient.name }}
            </p>
          </ul>
        </div>
      </template>
    </Popup>
    <PopupDelete
      v-if="showDeletePopup"
      v-on:onClosePopup="onCancelDeletePopup"
      v-on:onDelete="onDeleteIngredient"
      headerText="Eliminar ingrediente"
      :bodyText="`¿Deseas eliminar el ingrediente '${deleteIngredient.name}'?`"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { UPDATE_INGREDIENT, DELETE_INGREDIENT } from "../store/actions";
import { INGREDIENT_SORT_TYPES, SORT_ORDERS } from "../util/constants";
import { guessPlural, getFormattedDate } from "../util/utils";

export default {
  name: "IngredientsList",
  data() {
    return {
      primaryCheck: true,
      secondaryCheck: true,
      tertiaryCheck: true,
      groupCheck: false,
      sortType: INGREDIENT_SORT_TYPES.BY_CREATION_DATE,
      sortOrder: SORT_ORDERS.DESC,
      search: "",
      groupSearch: "",
      showDeletePopup: false,
      deleteIngredient: null,
      edition: {
        show: false,
        id: null,
        name: null,
        type: null,
        group: null,
      },
      recipes: {
        show: false,
        ingredient: {},
      },
    };
  },
  computed: {
    totalIngredients() {
      return this.filteredIngredients.length;
    },
    ingredientSortTypes() {
      return INGREDIENT_SORT_TYPES;
    },
    sortOrders() {
      return SORT_ORDERS;
    },
    filteredIngredients() {
      return this.filterIngredients({
        search: this.search,
        primary: this.primaryCheck,
        secondary: this.secondaryCheck,
        tertiary: this.tertiaryCheck,
        group: this.groupCheck,
      });
    },
    filteredGroups() {
      return this.filterIngredientGroups(this.groupSearch);
    },
    ...mapState(["ingredients", "user"]),
    ...mapGetters([
      "filterIngredients",
      "sortIngredients",
      "filterIngredientGroups",
    ]),
  },
  methods: {
    guessPlural,
    getFormattedDate,
    onSort() {
      this.filteredIngredients = this.sortIngredients(
        this.filteredIngredients,
        this.sortType,
        this.sortOrder
      );
    },
    onPreDeleteIngredient(ingredient) {
      this.showDeletePopup = true;
      this.deleteIngredient = ingredient;
    },
    onCancelDeletePopup() {
      this.showDeletePopup = false;
      this.deleteIngredient = null;
    },
    onDeleteIngredient() {
      this.$store.dispatch(DELETE_INGREDIENT, {
        ingredient: this.deleteIngredient,
      });
      this.showDeletePopup = false;
      this.deleteIngredient = null;
    },
    onCancelEditionPopup() {
      this.edition.show = false;
      this.edition.id = null;
      this.edition.name = null;
      this.edition.type = null;
      this.edition.group = null;
      this.groupSearch = "";
    },
    onCancelRecipesPopup() {
      this.recipes = {
        show: false,
        ingredient: {},
      };
    },
    onEditIngredient(ingredient) {
      this.edition.show = true;
      this.edition.id = ingredient._id;
      this.edition.name = ingredient.name;
      this.edition.type = ingredient.type;
      this.edition.group = ingredient.group;
      this.groupSearch = ingredient.group;
    },
    onAddGroup(group) {
      this.edition.group = group;
      this.groupSearch = group;
    },
    onSaveIngredient() {
      this.edition.show = false;
      const ingredient = {
        _id: this.edition.id,
        name: this.edition.name.toLowerCase(),
        type: parseInt(this.edition.type),
        group: this.edition.group || this.groupSearch,
        updatedBy: this.user.username,
      };
      this.groupSearch = "";
      this.$store.dispatch(UPDATE_INGREDIENT, { ingredient });
    },
    onClickRecipes(ingredient) {
      this.recipes = {
        show: true,
        ingredient,
      };
    },
    onClickRecipe(recipe) {
      this.$router.push("/recipe/" + recipe._id);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .input {
  padding: 0.5rem 1em;
  border: 1px solid #eaecef;
  outline: none;
  font-size: 1.1rem;
  width: 8rem;
}
.header .check {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
}
.header .label-check {
  padding-left: 0.3rem;
}
.header .select {
  margin-left: 0.5rem;
  padding: 0.5rem 1em;
  border: 1px solid #d4d9e0;
  outline: none;
  color: #2c3e50;
}
.header .label-select {
  margin-left: 5rem;
  color: #2c3e50;
}
.header .total {
  margin-left: 2rem;
  color: #949494;
  font-style: italic;
}
.header input::placeholder {
  color: #afafaf;
}
.ingredient-list {
  margin: 1rem;
  padding: 0;
}
.ingredient-list-item {
  padding: 0.5rem 0;
  background-color: #fff;
  border-bottom: 1px solid #eaecef;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ingredient {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ingredient-icon {
  width: 0.8rem;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4rem;
  border-radius: 0.1rem;
}
.ingredient-icon.icon-1 {
  background-color: #c53d3d;
}
.ingredient-icon.icon-2 {
  background-color: #ff9a9a;
}
.ingredient-icon.icon-3 {
  background-color: #c7c7c7;
}
.ingredient-icon .icon {
  color: #fff;
  font-size: 0.5rem;
  font-weight: bold;
}
.ingredient-text {
  font-size: 1.1rem;
}
.ingredient-group {
  font-size: 1.1rem;
}
.ingredient-stats {
  font-style: italic;
  margin-left: 2rem;
  color: #8c8c8c;
  cursor: pointer;
}
.ingredient-stats:hover {
  text-decoration: underline;
}
.ingredient-options {
  display: none;
  margin-left: 2rem;
}
.ingredient-list-item:hover .ingredient-options {
  display: block;
}
.ingredient-options-icon {
  font-size: 1.3rem;
  color: #a11f1f;
  padding-right: 0.5rem;
  cursor: pointer;
}
.date {
  font-size: 0.9rem;
  font-style: italic;
  color: rgb(108, 108, 108);
  margin-left: 1rem;
}
.popup-group {
  margin-top: 0;
}
.filtered-groups {
  border: 1px solid #d4d9e0;
  border-top: none;
  width: 16rem;
  max-height: 25rem;
  overflow-y: scroll;
}
.filtered-groups ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filtered-groups ul li {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.filtered-groups .group-new {
  padding: 0.5rem;
}
.ingredient-group {
  margin-left: 1rem;
}
.ingredient-group .name {
  padding: 0.1rem 0.3rem;
  background-color: #ccc;
  border-radius: 5px;
  font-size: 0.8rem;
}
.add-new-group {
  color: green;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup.ingredient-popup {
  align-items: flex-start;
  width: 17rem;
  padding: 1rem;
}
.popup-icon {
  color: #2e4f98;
  margin-right: 0.7rem;
  font-size: 1.5rem;
}
.popup-title-ingredient {
  font-style: italic;
  margin-left: 0.3rem;
  font-weight: bold;
}
.popup-subtitle {
  margin: 1rem 0 0.5rem 0;
}
.popup-input {
  padding: 0.5rem;
  font-size: 0.9rem;
  width: 15rem;
  border: 1px solid #d4d9e0;
}
.popup-radio {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.popup-radio-item {
  display: flex;
  flex-direction: row;
}
.popup-radio label {
  padding: 0 1rem 0 0.3rem;
}
.popup-button {
  margin-left: 1rem;
}
.popup ul {
  margin: 0;
  padding: 0;
}
.popup ul li {
  padding: 0.5rem;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}
.popup ul li:hover {
  background-color: rgb(244, 244, 244);
}
</style>
