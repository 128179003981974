<template>
  <div class="recipe-edit">
    <div class="view-title">
      <span v-if="recipeId">
        Editar receta <b>{{ recipe.title }}</b>
      </span>
      <span v-if="!recipeId">Nueva receta</span>
      <span v-if="!recipeId">
        <Button
          type="generate"
          v-on:onClickButton="onGenerateRecipe"
          text="Generar receta"
          />
          <Button
          type="generate"
          :disabled="!isRecipeGenerated"
          v-on:onClickButton="onGenerateRecipeImage"
          text="Generar imagen"
        />
      </span>
      <span v-if="recipeId">
          <Button
          type="generate"
          v-on:onClickButton="onGenerateRecipeImage"
          text="Generar imagen"
        />
      </span>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Título</div>
        <input
          class="input"
          type="text"
          @keyup="onTypeTitle"
          v-model.trim="recipe.title"
        />
        <div v-if="similarRecipes.length" class="similar">
          <span>Recetas similares:</span>
          <ul>
            <li v-for="similarRecipe in similarRecipes" :key="similarRecipe.title">
              {{ similarRecipe.distance }} - {{ similarRecipe.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Imagen</div>
        <div class="add-image" v-if="!recipeId" @click="onAddRecipeImage">
          <font-awesome-icon :icon="['fas', 'plus']" /> Añadir imagen
        </div>
        <div class="add-image" v-if="recipeId" @click="onChangeRecipeImage">
          <font-awesome-icon :icon="['fas', 'plus']" /> Cambiar imagen
        </div>
        <img
          class="image"
          v-if="recipeImageData"
          :src="recipeImageData.dataURL"
        />
        <img
          class="image"
          v-if="recipeId && !recipeImageData"
          :src="recipeImageURL"
        />
      </div>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Tiempo (min.)</div>
        <input
          class="input input-number"
          type="number"
          min="5"
          v-model.number="recipe.time"
        />
      </div>
      <div class="field">
        <div class="label">Nº de personas</div>
        <input
          class="input input-number"
          type="number"
          min="1"
          v-model.number="recipe.people"
        />
      </div>
      <div class="field">
        <div class="label">Dificultad</div>
        <select class="select" v-model="recipe.difficulty">
          <option :value="1">Fácil</option>
          <option :value="2">Media</option>
          <option :value="3">Difícil</option>
        </select>
      </div>
      <div class="field author">
        <div class="label">Autor</div>
        <input
          class="input input-author"
          type="text"
          v-model.trim="authorSearch"
        />
        <div
          v-if="
            authorSearch &&
            ((filteredAuthors.length && authorSearch !== recipe.author) ||
              filteredAuthors.length === 0)
          "
          class="filtered-authors"
        >
          <ul v-if="filteredAuthors.length">
            <li
              @click="onAddAuthor(author)"
              v-for="author in filteredAuthors"
              :key="author"
            >
              {{ author }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Receta</div>
        <div v-for="(part, index) in recipe.recipe" :key="index">
          <input
            class="input"
            type="text"
            v-model.trim="recipe.recipe[index]"
          />
          <font-awesome-icon
            class="remove-recipe"
            @click="onDeleteRecipePart(index)"
            :icon="['fas', 'times']"
          />
        </div>
        <div class="add-recipe" @click="onAddRecipePart">
          <font-awesome-icon :icon="['fas', 'plus']" /> Añadir
        </div>
      </div>
    </div>
    <div class="row">
      <div class="field area">
        <div class="label">Preparación</div>
        <textarea class="textarea" v-model.trim="recipe.preparation"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Ingredientes</div>
        <div v-if="generatedIngredients" class="generated-ingredients">
          <Tag
            v-for="(generatedIngredient, index) in generatedIngredients"
            :key="index"
            type="generated-ingredient"
            :text="generatedIngredient"
            closable="false"
          />
        </div>
        <div class="ingredients">
          <Tag
            v-for="(ingredient, index) in recipe.ingredients"
            :key="index"
            type="ingredient"
            :text="ingredient.name"
            closable="true"
            v-on:onCloseTag="onDeleteIngredient(index)"
          />
        </div>
        <div>
          <div>
            <font-awesome-icon :icon="['fas', 'plus']" /> Añadir ingrediente
          </div>
          <input
            class="input input-ingredient"
            type="text"
            v-model="ingredientSearch"
          />
          <div v-if="ingredientSearch" class="filtered-ingredients">
            <ul v-if="filteredIngredients.length">
              <li
                @click="onAddIngredient(ing)"
                v-for="ing in filteredIngredients"
                :key="ing._id"
              >
                {{ ing.name }}
              </li>
            </ul>
            <div
              @click="onAddNewIngredient"
              class="ingredient-new"
              v-if="
                filteredIngredients.length === 0 ||
                !isExactIngredientCoincidence
              "
            >
              <font-awesome-icon
                class="add-new-ingredient"
                :icon="['fas', 'plus']"
              />
              Crear nuevo ingrediente <b>{{ ingredientSearch }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <div class="label">Categorías</div>
        <div v-if="generatedCategories" class="generated-categories">
          <Tag
            v-for="(generatedCategory, index) in generatedCategories"
            :key="index"
            type="generated-category"
            :text="generatedCategory"
            closable="false"
          />
        </div>
        <div class="categories">
          <Tag
            v-for="(category, index) in recipe.categories"
            :key="index"
            type="category"
            :text="category.name"
            closable="true"
            v-on:onCloseTag="onDeleteCategory(index)"
          />
        </div>
        <div>
          <div>
            <font-awesome-icon :icon="['fas', 'plus']" /> Añadir categoría
          </div>
          <input
            class="input input-category"
            type="text"
            v-model="categorySearch"
          />
          <div v-if="categorySearch" class="filtered-categories">
            <ul v-if="filteredCategories.length">
              <li
                @click="onAddCategory(cat)"
                v-for="cat in filteredCategories"
                :key="cat._id"
              >
                {{ cat.name }}
              </li>
            </ul>
            <div
              @click="onAddNewCategory"
              class="category-new"
              v-if="
                filteredCategories.length === 0 || !isExactCategoryCoincidence
              "
            >
              <font-awesome-icon
                class="add-new-category"
                :icon="['fas', 'plus']"
              />
              Crear nueva categoría <b>{{ categorySearch }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Button
        class="footer-button"
        v-on:onClickButton="onCancel"
        text="Cancelar"
      />
      <Button
        class="footer-button"
        type="save"
        v-on:onClickButton="onPreSave"
        text="Guardar"
      />
    </div>
    <Popup
      v-if="showIngredientsPopup"
      v-on:onClosePopup="onCancelIngredientsPopup"
      headerText="Ingredientes nuevos"
    >
      <template v-slot:body>
        <p class="ingredients-popup-text">
          Selecciona el tipo de ingrediente en cada caso:
        </p>
        <ul class="ingredients-popup-list">
          <li
            class="ingredients-popup-item"
            v-for="(ingredient, index) in newIngredients"
            :key="index"
          >
            <b>{{ ingredient.name }}</b>
            <div class="ingredients-popup-radio">
              <input
                class="input-radio"
                type="radio"
                id="type1"
                value="1"
                v-model="ingredient.type"
              />
              <label for="type1">Principal</label>
              <input
                class="input-radio"
                type="radio"
                id="type2"
                value="2"
                v-model="ingredient.type"
              />
              <label for="type2">Secundario</label>
              <input
                class="input-radio"
                type="radio"
                id="type3"
                value="3"
                v-model="ingredient.type"
              />
              <label for="type3">Terciario</label>
            </div>
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <Button
          class="ingredients-popup-button"
          v-on:onClickButton="onCancelIngredientsPopup"
          text="Cancelar"
        />
        <Button
          class="ingredients-popup-button"
          type="save"
          v-on:onClickButton="onSaveIngredientsPopup"
          text="Guardar"
        />
      </template>
    </Popup>
    <Popup
      v-if="showCategoriesPopup"
      v-on:onClosePopup="onCancelCategoriesPopup"
      headerText="Categorías nuevas"
    >
      <template v-slot:body>
        <p class="categories-popup-text">Añade imágenes a las categorías</p>
        <ul class="categories-popup-list">
          <li
            class="categories-popup-item"
            v-for="(category, index) in newCategories"
            :key="index"
          >
            <div>
              <b>{{ category.name }}</b>
              <Button
                v-if="!category.categoryImageData"
                class="categories-popup-add-image"
                v-on:onClickButton="onAddCategoryImage(category)"
                text="Añadir imagen"
              />
              <Button
                v-if="category.categoryImageData"
                class="categories-popup-add-image"
                v-on:onClickButton="onChangeCategoryImage(category)"
                text="Cambiar imagen"
              />
            </div>
            <img
              v-if="category.categoryImageData"
              :src="category.categoryImageData.dataURL"
            />
          </li>
        </ul>
      </template>
      <template v-slot:footer>
        <Button
          class="categories-popup-button"
          v-on:onClickButton="onCancelCategoriesPopup"
          text="Cancelar"
        />
        <Button
          class="categories-popup-button"
          type="save"
          v-on:onClickButton="onSaveCategoriesPopup"
          text="Guardar"
        />
      </template>
    </Popup>
    <Modal
      v-if="showRecipeImageCropper"
      v-on:onCloseModal="onCancelRecipeImageCropper"
    >
      <template v-slot:header>Añadir imagen</template>
      <template v-slot:body>
        <Cropper v-on:onCrop="onCropRecipeImage" />
      </template>
      <template v-slot:footer>
        <Button
          class="cropper-button"
          v-on:onClickButton="onCancelRecipeImageCropper"
          text="Cancelar"
        />
        <Button
          class="cropper-button"
          type="save"
          v-on:onClickButton="onSaveRecipeImageCropper"
          text="Guardar"
        />
      </template>
    </Modal>
    <Modal
      v-if="showCategoryImageCropper"
      v-on:onCloseModal="onCancelCategoryImageCropper"
    >
      <template v-slot:header>Añadir imagen</template>
      <template v-slot:body>
        <Cropper v-on:onCrop="onCropCategoryImage" />
      </template>
      <template v-slot:footer>
        <Button
          class="cropper-button"
          v-on:onClickButton="onCancelCategoryImageCropper"
          text="Cancelar"
        />
        <Button
          class="cropper-button"
          type="save"
          v-on:onClickButton="onSaveCategoryImageCropper"
          text="Guardar"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { CREATE_RECIPE, UPDATE_RECIPE, SHOW_ERROR } from "../store/actions";
import {
  clone,
  createImageName,
  getImageURL,
  normalizeString,
  base64ToBlob,
} from "../util/utils";

// TODO: que al pasar por los campos con Tab y llegar al select, éste se abra automáticamente
// TODO: que al pulsar enter en el último elemento de recipe.recipe se añada automáticamente otro elemento al array con foco para escribir

export default {
  name: "RecipeEdit",
  props: {
    recipeId: String,
  },
  computed: {
    newIngredients() {
      return this.recipe.ingredients.filter((ing) => ing.isNew);
    },
    newCategories() {
      return this.recipe.categories.filter((ing) => ing.isNew);
    },
    filteredIngredients() {
      return this.filterIngredients({
        search: this.ingredientSearch,
        primary: true,
        secondary: true,
        tertiary: true,
      });
    },
    isExactIngredientCoincidence() {
      return this.filterIngredients({
        search: this.ingredientSearch,
        primary: true,
        secondary: true,
        tertiary: true,
      }).some(
        (ingredient) =>
          normalizeString(ingredient.name) ===
          normalizeString(this.ingredientSearch)
      );
    },
    filteredCategories() {
      return this.filterCategories(this.categorySearch);
    },
    isExactCategoryCoincidence() {
      return this.filterCategories(this.categorySearch, true, true).some(
        (category) =>
          normalizeString(category.name) ===
          normalizeString(this.categorySearch)
      );
    },
    filteredAuthors() {
      return this.filterAuthors(this.authorSearch);
    },
    ...mapState(["recipes", "user"]),
    ...mapGetters([
      "filterCategories",
      "filterIngredients",
      "findRecipeByTitle",
      "filterAuthors",
      "findIngredientByName",
      "findCategoryByName",
      "jaroWinklerRecipeTitles",
    ]),
  },
  data() {
    return {
      ingredientSearch: "",
      categorySearch: "",
      authorSearch: "",
      showIngredientsPopup: false,
      showCategoriesPopup: false,
      showRecipeImageCropper: false,
      showCategoryImageCropper: false,
      currentCategoryCropping: null,
      recipeImageURL: null,
      recipeImageData: null,
      croppedRecipeImageData: null,
      categoryImageData: null,
      croppedCategoryImageData: null,
      isRecipeGenerated: false,
      similarRecipes: [],
      recipe: this.recipeId
        ? clone(this.$store.getters["findRecipeById"](this.recipeId))
        : {
            title: "",
            image: "",
            time: null,
            people: null,
            difficulty: null,
            author: "",
            recipe: [],
            preparation: "",
            ingredients: [],
            categories: [],
          },
        generatedIngredients: null,
        generatedCategories: null,
    };
  },
  methods: {
    getImageURL,
    onTypeTitle() {
      this.recipe.image = createImageName(this.recipe.title);
      this.similarRecipes = this.jaroWinklerRecipeTitles(this.recipe.title);
    },
    onAddAuthor(author) {
      this.recipe.author = author;
      this.authorSearch = author;
    },
    onAddRecipePart() {
      this.recipe.recipe.push("");
    },
    onDeleteRecipePart(index) {
      this.recipe.recipe.splice(index, 1);
    },
    onAddIngredient(ingredient) {
      if (this.recipe.ingredients.some((ing) => ing._id === ingredient._id)) {
        this.$store.dispatch(
          SHOW_ERROR,
          `El ingrediente '${ingredient.name}' ya ha sido agregado`
        );
      } else {
        this.recipe.ingredients.push(ingredient);
        this.ingredientSearch = "";
      }
    },
    onAddNewIngredient() {
      this.recipe.ingredients.push({
        name: this.ingredientSearch.toLowerCase().trim(),
        type: 2,
        isNew: true,
        createdBy: this.user.username,
      });
      this.ingredientSearch = "";
    },
    onDeleteIngredient(index) {
      this.recipe.ingredients.splice(index, 1);
    },
    onAddCategory(category) {
      if (this.recipe.categories.some((cat) => cat._id === category._id)) {
        this.$store.dispatch(
          SHOW_ERROR,
          `La categoría '${category.name}' ya ha sido agregada`
        );
      } else {
        this.recipe.categories.push(category);
        this.categorySearch = "";
      }
    },
    onAddNewCategory() {
      this.recipe.categories.push({
        name: this.categorySearch,
        image: createImageName(this.categorySearch),
        categoryImageData: null,
        isNew: true,
        createdBy: this.user.username,
      });
      this.categorySearch = "";
    },
    onDeleteCategory(index) {
      this.recipe.categories.splice(index, 1);
    },
    onCancel() {
      this.$router.back();
    },
    onCancelIngredientsPopup() {
      this.showIngredientsPopup = false;
    },
    onCancelCategoriesPopup() {
      this.showCategoriesPopup = false;
    },
    onAddRecipeImage() {
      this.showRecipeImageCropper = true;
    },
    onAddCategoryImage(category) {
      this.showCategoryImageCropper = true;
      this.currentCategoryCropping = category;
    },
    onChangeCategoryImage(category) {
      this.showCategoryImageCropper = true;
      this.currentCategoryCropping = category;
    },
    onChangeRecipeImage() {
      this.showRecipeImageCropper = true;
    },
    onCropRecipeImage({ dataURL, blob }) {
      this.croppedRecipeImageData = {
        dataURL,
        blob,
      };
    },
    onCancelRecipeImageCropper() {
      this.croppedRecipeImageData = null;
      this.showRecipeImageCropper = false;
    },
    onSaveRecipeImageCropper() {
      if (this.croppedRecipeImageData)
        this.recipeImageData = this.croppedRecipeImageData;
      this.showRecipeImageCropper = false;
    },
    onCropCategoryImage({ dataURL, blob }) {
      const category = this.recipe.categories.find(
        (cat) => cat.name === this.currentCategoryCropping.name
      );
      category.categoryImageData = {
        dataURL,
        blob,
      };
    },
    onCancelCategoryImageCropper() {
      this.showCategoryImageCropper = false;
      this.currentCategoryCropping = null;
    },
    onSaveCategoryImageCropper() {
      this.showCategoryImageCropper = false;
    },
    onPreSave() {
      if (this.authorSearch) this.recipe.author = this.authorSearch;
      if (this.recipe._id) this.recipe.updatedBy = this.user.username;
      if (!this.recipe._id) this.recipe.createdBy = this.user.username;
      if (!this.recipe.title)
        return this.$store.dispatch(SHOW_ERROR, `Debes especificar el título`);
      if (!this.recipe._id && this.findRecipeByTitle(this.recipe.title))
        return this.$store.dispatch(
          SHOW_ERROR,
          `Ya existe una receta con el mismo título`
        );
      if (!this.recipe._id && !this.recipeImageData)
        return this.$store.dispatch(SHOW_ERROR, `Debes añadir una imagen`);
      if (!this.recipe.time)
        return this.$store.dispatch(SHOW_ERROR, `Debes especificar el tiempo`);
      if (this.recipe.time < 5)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar un tiempo mayor o igual a 5 minutos`
        );
      if (this.recipe.time >= 4000)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar un tiempo menor`
        );
      if (!this.recipe.people)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar el nº de personas`
        );
      if (this.recipe.people < 1)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar un nº de personas mayor o igual a 1`
        );
      if (this.recipe.people > 10)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar un nº de personas menor`
        );
      if (!this.recipe.difficulty)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar la dificultad`
        );
      if (
        !this.recipe.recipe ||
        this.recipe.recipe.length === 0 ||
        !this.recipe.recipe[0].trim()
      )
        return this.$store.dispatch(SHOW_ERROR, `Debes especificar la receta`);
      if (!this.recipe.preparation)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar la preparación`
        );
      if (!this.recipe.ingredients || this.recipe.ingredients.length === 0)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar los ingredientes`
        );
      if (!this.recipe.categories || this.recipe.categories.length === 0)
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes especificar las categorías`
        );

      if (this.newIngredients.length) {
        this.showIngredientsPopup = true;
      } else if (this.newCategories.length) {
        this.showCategoriesPopup = true;
      } else {
        this.onSave();
      }
    },
    onSaveIngredientsPopup() {
      this.showIngredientsPopup = false;
      if (this.newCategories.length) {
        this.showCategoriesPopup = true;
      } else {
        this.onSave();
      }
    },
    onSaveCategoriesPopup() {
      const categoriesWithoutImage = this.newCategories.filter(
        (cat) => !cat.categoryImageData
      );
      if (categoriesWithoutImage.length) {
        return this.$store.dispatch(
          SHOW_ERROR,
          `Debes añadir una imagen para las nuevas categorías: ${categoriesWithoutImage
            .map((cat) => cat.name)
            .join(",")}`
        );
      }
      this.showCategoriesPopup = false;
      this.onSave();
    },
    onSave() {
      const action = this.recipe._id ? UPDATE_RECIPE : CREATE_RECIPE;
      if (this.isRecipeGenerated) this.isRecipeGenerated = false;
      if (this.similarRecipes.length) this.similarRecipes = [];
      this.$store.dispatch(action, {
        recipe: this.recipe,
        recipeImageData: this.recipeImageData,
      });
    },
    async onGenerateRecipe() {
      const generatedRecipe = await this.$store.dispatch("GENERATE_RECIPE");

      const { title, people, time, difficulty, recipe, ingredients, preparation, categories } = JSON.parse(generatedRecipe);

      this.similarRecipes = this.jaroWinklerRecipeTitles(title);
      
      this.isRecipeGenerated = true;

      this.recipe.title = title;
      this.recipe.image = createImageName(title);
      this.recipe.people = people;
      this.recipe.time = time;
      this.recipe.difficulty = difficulty;
      this.recipe.recipe = recipe;
      this.recipe.preparation = preparation;
      this.recipe.ingredients = [];
      this.recipe.categories = [];
      this.generatedIngredients = [];
      this.generatedCategories = [];

      ingredients.forEach(ingredient => {
        const foundIngredient = this.findIngredientByName(ingredient);
        if (foundIngredient) {
          this.recipe.ingredients.push(foundIngredient);
        } else {
          this.generatedIngredients.push(ingredient);
        }
      });

      categories.forEach(category => {
        const foundCategory = this.findCategoryByName(category);
        if (foundCategory) {
          this.recipe.categories.push(foundCategory);
        } else {
          this.generatedCategories.push(category);
        }
      });
    },
    async onGenerateRecipeImage() {
      if (!this.recipe.title) return;

      const generatedImage = await this.$store.dispatch("GENERATE_RECIPE_IMAGE", {
        recipeInfo: `Título de la receta: ${this.recipe.title}; Ingredientes de la receta: ${this.recipe.recipe.join()}; Preparación: ${this.recipe.preparation}`,
      });

      const b64data = generatedImage.data[0].b64_json;

      this.recipeImageData = {
        dataURL: `data:image/jpg;base64,${b64data}`,
        blob: base64ToBlob(b64data),
      };
    },
  },
  mounted() {
    if (this.recipeId) {
      this.recipeImageURL = getImageURL("recipes", this.recipe.image);
      this.authorSearch = this.recipe.author;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recipe-edit {
  margin-bottom: 5rem;
}
.view-title {
  font-size: 1.3rem;
}
.row {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.field {
  margin-right: 2rem;
}
.label {
  font-weight: bold;
}
.field.area {
  width: 100%;
  margin-right: 1rem;
}
.input {
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  width: 25rem;
  border: 1px solid #d4d9e0;
}
.input-number {
  width: 6rem;
}
.input-author {
  width: 15rem;
}
.input-ingredient {
  width: 25rem;
}
.input-category {
  width: 25rem;
}
.input:focus-visible {
  outline: #a9a9a9 auto 1px;
}
.select {
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  width: 8rem;
  border: 1px solid #d4d9e0;
  outline: none;
}
.textarea {
  margin-top: 0.5rem;
  padding: 0.5rem;
  width: 100%;
  height: 15rem;
  font-size: 0.9rem;
  border: 1px solid #d4d9e0;
}
.textarea:focus-visible {
  outline: #a9a9a9 auto 1px;
}
.similar {
  padding: 0.5rem;
  border: 1px solid #ccc;
  margin: 0.5rem 0;
}
.similar ul {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-top: 1px dashed #ccc;
}
.add-image {
  margin: 0.8rem 0 0 0.5rem;
  cursor: pointer;
}
.image {
  margin: 0.8rem 0 0 0.5rem;
  height: 15rem;
}
.remove-recipe {
  margin-left: 0.5rem;
  cursor: pointer;
}
.filtered-authors {
  border: 1px solid #d4d9e0;
  border-top: none;
  width: 16rem;
  max-height: 25rem;
  overflow-y: scroll;
  position: absolute;
}
.filtered-authors ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filtered-authors ul li {
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.add-recipe {
  margin: 0.8rem 0 0 0.5rem;
  cursor: pointer;
}
.ingredients {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.remove-ingredient {
  margin-left: 0.3rem;
  cursor: pointer;
}
.filtered-ingredients {
  border: 1px solid #d4d9e0;
  border-top: none;
  padding: 0 0.5rem;
  width: 25rem;
  max-height: 25rem;
  overflow-y: scroll;
}
.filtered-ingredients ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filtered-ingredients ul li {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.filtered-ingredients .ingredient-new {
  padding: 0.5rem 0;
  cursor: pointer;
}
.add-new-ingredient {
  color: green;
}
.categories {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.remove-category {
  margin-left: 0.3rem;
  cursor: pointer;
}
.filtered-categories {
  border: 1px solid #d4d9e0;
  border-top: none;
  padding: 0 0.5rem;
  width: 25rem;
  max-height: 25rem;
  overflow-y: scroll;
}
.filtered-categories ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filtered-categories ul li {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.filtered-categories .category-new {
  padding: 0.5rem 0;
  cursor: pointer;
}
.add-new-category {
  color: green;
}
.footer {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-top: 1px solid #eaecef;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}
.footer-button {
  margin-left: 1rem;
}
.ingredients-popup-icon {
  color: #2e4f98;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}
.ingredients-popup-text {
  margin: 0;
  padding-bottom: 1rem;
}
.ingredients-popup-list {
  padding: 0;
  margin: 0;
}
.ingredients-popup-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
}
.ingredients-popup-radio {
  margin-left: 2rem;
}
.ingredients-popup-radio label {
  padding: 0 1rem 0 0.3rem;
}
.ingredients-popup-button {
  margin-left: 1rem;
}
.cropper-button {
  margin-left: 1rem;
}
.categories-popup-icon {
  color: #2e4f98;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}
.categories-popup-text {
  margin: 0;
  padding-bottom: 1rem;
}
.categories-popup-list {
  padding: 0;
  margin: 0;
}
.categories-popup-item {
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0;
  margin: 1rem 0;
}
.categories-popup-item img {
  max-width: 20rem;
  margin-top: 1rem;
}
.categories-popup-add-image {
  margin-left: 1rem;
  cursor: pointer;
}
.categories-popup-button {
  margin-left: 1rem;
}
</style>
