<template>
  <div>
    <div class="header">Configuración</div>
    <ul class="config-list">
      <li
        class="config-list-item"
        v-for="(value, key, index) in config.value"
        :key="index"
      >
        {{ configConstants[key].text }}:
        <span
          v-if="configConstants[key].type === 'number'"
          class="config-value"
          >{{ value }}</span
        >
        <span
          v-if="configConstants[key].type === 'boolean'"
          class="config-value"
          >{{ value === true ? "Sí" : "No" }}</span
        >
        <div class="config-options">
          <font-awesome-icon
            @click="onEditConfig(key, value)"
            :icon="['fas', 'edit']"
            class="config-options-icon"
          />
        </div>
      </li>
    </ul>
    <Popup
      v-if="edition.show"
      v-on:onClosePopup="onCancelEditionPopup"
      headerText="Editar configuración"
    >
      <template v-slot:body>
        <div class="popup config-popup">
          <div class="config-popup-key">
            {{ configConstants[edition.key].text }}:
          </div>
          <input
            v-if="configConstants[edition.key].type === 'number'"
            class="popup-input"
            type="number"
            v-model.number="edition.value"
          />
          <div
            v-if="configConstants[edition.key].type === 'boolean'"
            class="config-popup-radio"
          >
            <input
              class="input-radio"
              type="radio"
              id="value1"
              :value="true"
              v-model="edition.value"
            />
            <label for="value1">Sí</label>
            <input
              class="input-radio"
              type="radio"
              id="value2"
              :value="false"
              v-model="edition.value"
            />
            <label for="value2">No</label>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <Button
          class="popup-button"
          v-on:onClickButton="onCancelEditionPopup"
          text="Cancelar"
        />
        <Button
          class="popup-button"
          type="save"
          v-on:onClickButton="onSaveConfig"
          text="Guardar"
        />
      </template>
    </Popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SHOW_ERROR, UPDATE_CONFIG } from "../store/actions";
import { CONFIG_CONSTANTS } from "../util/constants";

export default {
  name: "Config",
  data() {
    return {
      edition: {
        show: false,
        key: null,
        value: null,
      },
    };
  },
  computed: {
    ...mapState(["config"]),
    configConstants() {
      return CONFIG_CONSTANTS;
    },
  },
  methods: {
    onCancelEditionPopup() {
      this.edition.show = false;
      this.edition.key = null;
      this.edition.value = null;
    },
    onEditConfig(key, value) {
      this.edition.show = true;
      this.edition.key = key;
      this.edition.value = value;
    },
    validateNumberConfigValue(key, value, min, max) {
      if (!value || typeof value !== "number" || value < min || value > max)
        return false;
      return true;
    },
    validateBooleanConfigValue(key, value) {
      if (typeof value !== "boolean") return false;
      return true;
    },
    onSaveConfig() {
      const { type, min, max } = this.configConstants[this.edition.key];

      if (
        type === "number" &&
        !this.validateNumberConfigValue(
          this.edition.key,
          this.edition.value,
          min,
          max
        )
      ) {
        return this.$store.dispatch(
          SHOW_ERROR,
          `El valor debe estar comprendido entre ${min} y ${max}`
        );
      }
      if (
        type === "boolean" &&
        !this.validateBooleanConfigValue(this.edition.key, this.edition.value)
      ) {
        return this.$store.dispatch(SHOW_ERROR, `Valor incorrecto`);
      }

      this.edition.show = false;
      const config = {
        ...this.config,
        value: {
          ...this.config.value,
          [this.edition.key]: this.edition.value,
        },
      };
      this.$store.dispatch(UPDATE_CONFIG, { config });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  font-size: 1.3rem;
}
.config-list {
  margin: 1rem;
  padding: 0;
}
.config-list-item {
  padding: 0.5rem 0;
  line-height: 2rem;
  background-color: #fff;
  border-bottom: 1px solid #eaecef;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.config-list-item .config-value {
  margin-left: 1rem;
  font-weight: bold;
}
.config-options {
  display: none;
  margin-left: 2rem;
}
.config-list-item:hover .config-options {
  display: block;
}
.config-options-icon {
  font-size: 1.3rem;
  color: #a11f1f;
  padding-right: 0.5rem;
  cursor: pointer;
}
.popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup.config-popup {
  align-items: center;
  padding: 1rem;
}
.config-popup-key {
  margin-bottom: 1rem;
  text-align: center;
}
.config-popup-radio label {
  padding: 0 1rem 0 0.3rem;
}
.popup-icon {
  color: #2e4f98;
  margin-right: 0.7rem;
  font-size: 1.5rem;
}
.popup-input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  width: 3rem;
  border: 1px solid #d4d9e0;
}
.popup-button {
  margin-left: 1rem;
}
</style>
