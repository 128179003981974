import Button from "./components/Button.vue";
import Tag from "./components/Tag.vue";
import Popup from "./components/Popup.vue";
import PopupDelete from "./components/PopupDelete.vue";
import Modal from "./components/Modal.vue";
import Cropper from "./components/Cropper.vue";
import SignIn from "./components/SignIn.vue";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTimes, faEdit, faSpinner, faStar, faUserFriends, faClock, faUtensilSpoon, faAt, faExclamationTriangle, faHandPointer, faHeart, faShareAlt, faSync, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPlus, faTimes, faEdit, faSpinner, faStar, faUserFriends, faClock, faUtensilSpoon, faAt, faExclamationTriangle, faHandPointer, faHeart, faShareAlt, faSync, faSearch);

export default app => {
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.component('Cropper', Cropper);
    app.component('Button', Button);
    app.component('Tag', Tag);
    app.component('Popup', Popup);
    app.component('PopupDelete', PopupDelete);
    app.component('Modal', Modal);
    app.component('SignIn', SignIn);
};