<template>
  <div class="recipe-wrapper">
    <div class="recipe-left">
      <div class="title">{{ recipe.title }}</div>
      <div class="info">
        <div>
          <font-awesome-icon :icon="['fas', 'utensil-spoon']" class="icon" />{{
            getDifficulty(recipe.difficulty)
          }}
        </div>
        <div>
          <font-awesome-icon :icon="['fas', 'clock']" class="icon" />{{
            getFormattedTime(recipe.time)
          }}
        </div>
        <div>
          <font-awesome-icon :icon="['fas', 'user-friends']" class="icon" />
          {{ recipe.people }}
        </div>
        <div v-if="recipe.computedRating" class="rating">
          <font-awesome-icon :icon="['fas', 'star']" class="icon" />{{
            `${recipe.computedRating?.rating || '-'} (${recipe.computedRating?.totalRatings || '0'})`
          }}
          <div class="rating-tooltip">
            <div v-for="(value, key, index) in recipe.ratings" :key="key">
              <font-awesome-icon
                v-for="n in index + 1"
                :icon="['fas', 'star']"
                class="filled-star"
                :key="n"
              />
              <font-awesome-icon
                v-for="n in 4 - index"
                :icon="['fas', 'star']"
                class="empty-star"
                :key="n"
              />
              <span class="star-value">{{ value }}</span>
            </div>
          </div>
        </div>
        <div>
          <font-awesome-icon :icon="['fas', 'hand-pointer']" class="icon" />{{
            recipe.visited
          }}
        </div>
        <div>
          <font-awesome-icon :icon="['fas', 'heart']" class="icon" />{{
            recipe.favorites
          }}
        </div>
        <!-- <div>
              <font-awesome-icon :icon="['fas', 'share-alt']" class="icon" />{{
                recipe.shared
              }}
            </div> -->
        <div class="author" @click="onClickAuthor" v-if="recipe.author">
          <font-awesome-icon :icon="['fas', 'at']" class="icon" />{{
            recipe.author
          }}
        </div>
      </div>
      <div class="tags">
        <div class="categories">
          <Tag
            v-for="(category, index) in recipe.categories"
            :key="index"
            type="category"
            :text="category.name"
          />
        </div>
        <div class="ingredients">
          <Tag
            v-for="(ingredient, index) in recipe.ingredients"
            :key="index"
            type="ingredient"
            :text="ingredient.name"
          />
        </div>
      </div>
      <img class="image" :src="getImageURL('recipes', recipe.image)" />
      <div class="date">
        {{
          `Creada: ${getFormattedDate(recipe.createdAt)} ${
            recipe.createdBy || ""
          }`
        }}
      </div>
      <div class="date">
        {{
          `Modificada: ${getFormattedDate(recipe.updatedAt)} ${
            recipe.updatedBy || ""
          }`
        }}
      </div>
    </div>
    <div class="recipe-right">
      <div class="header-buttons">
        <Button
          class="header-button"
          :to="'/recipe/' + recipe._id + '/edit'"
          text="Editar"
        />
        <Button
          class="header-button"
          v-on:onClickButton="onPreDelete"
          type="delete"
          text="Eliminar"
        />
      </div>
      <div class="recipe">
        <ul>
          <li v-for="(part, key) in recipe.recipe" :key="key">
            {{ part }}
          </li>
        </ul>
      </div>
      <div class="preparation">
        {{ recipe.preparation }}
      </div>
    </div>
    <PopupDelete
      v-if="showDeletePopup"
      v-on:onClosePopup="onCancelDeletePopup"
      v-on:onDelete="onDelete"
      headerText="Eliminar receta"
      :bodyText="`¿Deseas eliminar la receta '${recipe.title}'?`"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DELETE_RECIPE } from "../store/actions";
import {
  getImageURL,
  getDifficulty,
  getFormattedTime,
  getFormattedDate,
} from "../util/utils";
import Tag from "./Tag";

export default {
  name: "Recipe",
  components: { Tag },
  props: {
    recipeId: String,
  },
  data() {
    return {
      recipe: this.$store.getters["findRecipeById"](this.recipeId),
      showDeletePopup: false,
    };
  },
  computed: {
    ...mapState(["recipes"]),
  },
  methods: {
    getImageURL,
    getDifficulty,
    getFormattedTime,
    getFormattedDate,
    onCancelDeletePopup() {
      this.showDeletePopup = false;
    },
    onPreDelete() {
      this.showDeletePopup = true;
    },
    onDelete() {
      this.$store.dispatch(DELETE_RECIPE, { recipe: this.recipe });
    },
    onClickAuthor() {
      window.open(`https://www.instagram.com/${this.recipe.author}`, "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recipe-wrapper {
  display: flex;
}
.recipe-left {
  flex: 1;
  padding-right: 0.5rem;
}
.recipe-right {
  flex: 1;
  padding-left: 0.5rem;
}
.image {
  width: 100%;
  box-shadow: 0px 0px 5px 0px #d0d0d0;
}
.date {
  font-size: 0.8rem;
  font-style: italic;
  color: rgb(108, 108, 108);
}
.header {
  display: flex;
  flex-direction: row;
}
.header-left {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.title-wrapper {
  display: flex;
  align-items: center;
}
.header-right {
  flex: 1;
}
.header-buttons {
  text-align: right;
  margin: 0.5rem 0 1.5rem 0;
}
.header-button {
  margin-left: 1rem;
}
.title {
  font-size: 1.3rem;
  font-weight: 500;
}
.info {
  display: flex;
  flex-direction: row;
}
.info div {
  margin-right: 1.2rem;
  color: #4a4a4a;
}
.info .icon {
  margin-right: 0.5rem;
}
.info .rating-tooltip {
  display: none;
}
.info .rating {
  cursor: help;
}
.info .rating:hover .rating-tooltip {
  display: block;
  position: absolute;
  background-color: rgb(74, 74, 74);
  padding: 0.5rem 0.8rem;
  box-shadow: 0px 0px 10px 0px rgb(74 74 74);
}
.info .rating:hover .rating-tooltip div {
  color: #fff;
  margin-right: 0;
}
.info .rating:hover .rating-tooltip .filled-star {
  color: #fff;
}
.info .rating:hover .rating-tooltip .empty-star {
  color: rgb(165, 165, 165);
}
.info .rating:hover .rating-tooltip .star-value {
  font-weight: bold;
  margin-left: 0.5rem;
}
.info .author {
  cursor: pointer;
}
.info .author:hover {
  text-decoration: underline;
}
.tags {
  padding: 0.5rem 0rem;
}
.recipe-preparation {
  display: flex;
  flex-direction: row;
}
.recipe {
  flex: 1;
}
.recipe ul {
  margin: 0;
  list-style: disc;
  columns: 2;
}
.recipe ul li {
  padding-bottom: 0.6rem;
}
.preparation {
  text-align: justify;
  padding: 1rem;
}
</style>
