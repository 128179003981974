<template>
  <div class="popup-wrapper">
    <div class="popup">
      <div class="popup-header">
        <div>{{ headerText }}</div>
        <font-awesome-icon
          class="close-icon"
          @click="onClose"
          :icon="['fas', 'times']"
        />
      </div>
      <div class="popup-body">
        <slot name="body"></slot>
      </div>
      <div v-if="footer" class="popup-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    footer: {
      type: Boolean,
      default: true,
    },
    headerText: String,
  },
  emits: ["onClosePopup"],
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.popup {
  background-color: #fff;
  opacity: 1;
  box-shadow: 0px 0px 10px 0px #272727;
}
.popup-header {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.popup-body {
  padding: 0.5rem 2rem;
  max-height: 70vh;
  overflow: scroll;
}
.popup-footer {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.close-icon {
  font-size: 1.5rem;
  cursor: pointer;
}
</style>