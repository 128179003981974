<template>
  <div>
    <div v-if="!user">
      <SignIn v-on:onSignIn="onSignIn" />
    </div>
    <div v-if="user">
      <Header :user="user.username" v-on:onSignOut="onSignOut" />
      <div v-if="isAppLoaded" class="wrapper">
        <div class="container">
          <router-view name="Home" />
          <router-view name="Search" />
          <router-view name="RecipesList" />
          <router-view name="CategoriesList" />
          <router-view name="IngredientsList" />
          <router-view name="Recipe" />
          <router-view name="RecipeEdit" />
          <router-view name="Config" />
        </div>
      </div>
      <div v-if="isLoading" class="loading">
        <font-awesome-icon :icon="['fas', 'spinner']" size="6x" pulse />
      </div>
      <div v-if="notifications.length" class="notifications">
        <div
          v-for="(notification, index) in notifications"
          :key="index"
          :class="['notification', `notification-type-${notification.type}`]"
          @click="onClickNotification(index)"
        >
          {{ notification.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_INITIAL_DATA } from "./store/actions";

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";

import Header from "./components/Header.vue";
import { SET_USER, CLEAR_SPECIFIC_NOTIFICATION } from "./store/mutations";

export default {
  name: "App",
  components: {
    Header,
  },
  computed: {
    ...mapState([
      "user",
      "isAppLoaded",
      "ingredients",
      "categories",
      "recipes",
      "isLoading",
      "notifications",
    ]),
  },
  methods: {
    onSignIn(authData) {
      localStorage.setItem(
        "idToken",
        authData.signInUserSession.idToken.jwtToken
      );
      this.$store.commit(SET_USER, { user: authData });
      this.$store.dispatch(GET_INITIAL_DATA);
    },
    async onSignOut() {
      await Auth.signOut();
      this.$store.commit(SET_USER, { user: null });
    },
    onClickNotification(index) {
      this.$store.commit(CLEAR_SPECIFIC_NOTIFICATION, { index });
    },
    refreshSession() {
      Auth.currentSession()
        .then((authData) => {
          localStorage.setItem(
            "idToken",
            authData.idToken.jwtToken,
          );
        })
        .catch((err) => console.log(err));
    }
  },
  created() {
    this.refreshSession();
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      if (authState === AuthState.SignedIn) this.onSignIn(authData);
    });
    window.onfocus = () => {
      this.refreshSession();
    };
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
};
</script>

<style>
body {
  margin: 0;
  background-color: #f9f9f9;
  box-sizing: border-box;
}
.wrapper {
  padding-top: 3rem;
}
.container {
  text-align: left;
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #eaecef;
}
.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6d1818;
  z-index: 1;
}
.notifications {
  position: fixed;
  top: 1rem;
  left: 20%;
  right: 20%;
  align-self: center;
  z-index: 1;
}
.notification {
  margin-bottom: 1rem;
  padding: 1rem;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0px 0px 10px 2px rgb(84, 84, 84);
  cursor: pointer;
}
.notification-icon {
  margin-right: 0.3rem;
}
.notification-type-1 {
  background-color: #dc4646;
}
.notification-type-2 {
  background-color: #59c152;
}
ul {
  list-style: none;
  text-align: left;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
