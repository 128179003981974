export const SET_USER = 'SET_USER';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_SPECIFIC_NOTIFICATION = 'CLEAR_SPECIFIC_NOTIFICATION';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const SET_INGREDIENT_GROUPS = 'SET_INGREDIENT_GROUPS';
export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SET_RECIPES = 'SET_RECIPES';
export const ADD_RECIPE = 'ADD_RECIPE';
export const UPDATE_RECIPE = 'UPDATE_RECIPE';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const SET_AUTHORS = 'SET_AUTHORS';
export const ADD_AUTHOR = 'ADD_AUTHOR';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_STATS = 'SET_STATS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

export default {
    [SET_USER](state, payload) {
        state.user = payload.user;
    },
    [SET_APP_LOADED](state) {
        state.isAppLoaded = true;
    },
    [SET_LOADING](state) {
        state.isLoading = true;
    },
    [CLEAR_LOADING](state) {
        state.isLoading = false;
    },
    [ADD_NOTIFICATION](state, payload) {
        state.notifications.push({
            type: payload.notificationType,
            message: payload.notificationMessage,
        });
    },
    [CLEAR_NOTIFICATION](state) {
        state.notifications.shift();
    },
    [CLEAR_SPECIFIC_NOTIFICATION](state, payload) {
        state.notifications.splice(payload.index, 1);
    },
    [SET_INGREDIENTS](state, payload) {
        state.ingredients = payload.ingredients;
    },
    [SET_INGREDIENT_GROUPS](state, payload) {
        state.ingredientGroups = payload.ingredientGroups;
    },
    [ADD_INGREDIENT](state, payload) {
        state.ingredients.push(payload.ingredient);
    },
    [UPDATE_INGREDIENT](state, payload) {
        const ingredientIndex = state.ingredients.findIndex(ingredient => ingredient._id === payload.ingredient._id);
        state.ingredients.splice(ingredientIndex, 1, payload.ingredient);
    },
    [DELETE_INGREDIENT](state, payload) {
        const ingredientIndex = state.ingredients.findIndex(ingredient => ingredient._id === payload.ingredient._id);
        state.ingredients.splice(ingredientIndex, 1);
    },
    [SET_CATEGORIES](state, payload) {
        state.categories = payload.categories;
    },
    [ADD_CATEGORY](state, payload) {
        state.categories.push(payload.category);
    },
    [UPDATE_CATEGORY](state, payload) {
        const categoryIndex = state.categories.findIndex(category => category._id === payload.category._id);
        state.categories.splice(categoryIndex, 1, payload.category);
    },
    [DELETE_CATEGORY](state, payload) {
        const categoryIndex = state.categories.findIndex(category => category._id === payload.category._id);
        state.categories.splice(categoryIndex, 1);
    },
    [SET_RECIPES](state, payload) {
        state.recipes = payload.recipes;
    },
    [ADD_RECIPE](state, payload) {
        state.recipes.unshift(payload.recipe);
    },
    [UPDATE_RECIPE](state, payload) {
        const recipeIndex = state.recipes.findIndex(recipe => recipe._id === payload.recipe._id);
        state.recipes.splice(recipeIndex, 1, payload.recipe);
    },
    [DELETE_RECIPE](state, payload) {
        const recipeIndex = state.recipes.findIndex(recipe => recipe._id === payload.recipe._id);
        state.recipes.splice(recipeIndex, 1);
    },
    [SET_AUTHORS](state, payload) {
        state.authors = payload.authors;
    },
    [ADD_AUTHOR](state, payload) {
        state.authors.push(payload.author);
    },
    [SET_CONFIG](state, payload) {
        state.config = payload.config;
    },
    [SET_STATS](state, payload) {
        state.stats = payload.stats;
    },
    [SET_SEARCH_RESULTS](state, payload) {
        state.search = payload.search;
    },
};