import { RECIPE_DIFFICULTIES, S3_URL, SORT_ORDERS } from './constants';

export const guessPlural = (number, string, pluralString) => {
    if (number === 1) return `${number} ${string}`;
    return `${number} ${pluralString}`;
};

export const sortByAttrValue = (attr, sortOrder = SORT_ORDERS.ASC, def = '-') => {
    return (a, b) => {
        if ((a[attr] || def) < (b[attr] || def)) return sortOrder === SORT_ORDERS.ASC ? -1 : 1;
        if ((a[attr] || def) > (b[attr] || def)) return sortOrder === SORT_ORDERS.ASC ? 1 : -1;
        return 0;
    };
};

export const sortBySubAttrValue = (attr, subAttr, sortOrder = SORT_ORDERS.ASC, def = '-') => {
    return (a, b) => {
        if ((a[attr][subAttr] || def) < (b[attr][subAttr] || def)) return sortOrder === SORT_ORDERS.ASC ? -1 : 1;
        if ((a[attr][subAttr] || def) > (b[attr][subAttr] || def)) return sortOrder === SORT_ORDERS.ASC ? 1 : -1;
        return 0;
    };
};

export const sortByAttrLength = (attr, sortOrder = SORT_ORDERS.ASC, def = 0) => {
    return (a, b) => {
        if ((a[attr]?.length || def) < (b[attr]?.length || def)) return sortOrder === SORT_ORDERS.ASC ? -1 : 1;
        if ((a[attr]?.length || def) > (b[attr]?.length || def)) return sortOrder === SORT_ORDERS.ASC ? 1 : -1;
        return 0;
    };
};

export const getImageURL = (path, name, forceDownload = true) => {
    return `${S3_URL[process.env.NODE_ENV]}${path}/${name}${forceDownload ? '?' + Date.now() : ''}`;
};

export const getDifficulty = difficulty => {
    switch (difficulty) {
        case RECIPE_DIFFICULTIES.EASY: return 'Fácil';
        case RECIPE_DIFFICULTIES.MEDIUM: return 'Medio';
        case RECIPE_DIFFICULTIES.HARD: return 'Difícil';
        default: return null
    }
};

export const getRatingValue = ratings => {
    let accumulatedRatingValue = 0;
    let totalRatings = 0;
    Object.keys(ratings).forEach((rating, index) => {
        const numberOfRatings = ratings[rating];
        if (numberOfRatings) {
            const star = index + 1;
            accumulatedRatingValue += numberOfRatings * star;
            totalRatings += numberOfRatings;
        }
    });
    if (totalRatings === 0) return { rating: null, totalRatings };
    const finalRatingValue = accumulatedRatingValue / totalRatings;
    const rating = Math.round(finalRatingValue * 100) / 100;
    return { rating, totalRatings };
};

export const getTotalRatings = ratings => {
    let accumulatedRatingValue = 0;
    let totalRatings = 0;
    Object.keys(ratings).forEach((rating, index) => {
        const numberOfRatings = ratings[rating];
        if (numberOfRatings) {
            const star = index + 1;
            accumulatedRatingValue += numberOfRatings * star;
            totalRatings += numberOfRatings;
        }
    });
    if (totalRatings === 0) return 0;
    const finalRatingValue = accumulatedRatingValue / totalRatings;
    return Math.round(finalRatingValue * 100) / 100;
};

export const getFormattedTime = time => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm' : '');
};

const ensureTwoDigits = value => value < 10 ? '0' + value : value;

const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
};

export const getFormattedDate = (date, { relative = false } = {}) => {
    if (!date) return '-';
    const d = new Date(date);
    const day = ensureTwoDigits(d.getDate());
    const month = ensureTwoDigits(d.getMonth() + 1);
    const year = d.getFullYear();
    const hours = ensureTwoDigits(d.getHours());
    const minutes = ensureTwoDigits(d.getMinutes());
    const seconds = ensureTwoDigits(d.getSeconds());
    let formattedDate = `${day}-${month}-${year}`;
    if (relative) {
        const now = new Date();
        if (isSameDay(now, d)) formattedDate = 'Hoy';
        now.setDate(now.getDate() - 1);
        if (isSameDay(now, d)) formattedDate = 'Ayer';
        now.setDate(now.getDate() - 2);
        if (isSameDay(now, d)) formattedDate = 'Hace 2 días';
        now.setDate(now.getDate() - 3);
        if (isSameDay(now, d)) formattedDate = 'Hace 3 días';
    }
    return `${formattedDate} ${hours}:${minutes}:${seconds}`;
};

export const normalizeString = string => string
    .trim()
    .toLowerCase()
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[èéêë]/g, 'e')
    .replace(/[ìíîï]/g, 'i')
    .replace(/[òóôõö]/g, 'o')
    .replace(/[ùúûü]/g, 'u');

export const createImageName = string => normalizeString(string)
    .replace(/,/g, '')
    .replace(/\//g, '')
    .replace(/\*/g, '')
    .replace(/  /g, ' ')

    .split(' ')
    .join('-')
    .replace(/--/g, '-')
    .replace(/- -/g, '-')
    .replace(/ñ/g, 'n')
    + '.jpg';

export const clone = object => JSON.parse(JSON.stringify(object));

export const base64ToBlob = (base64, mimeType) => {
    const binary = atob(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
    const arrayBuffer = new ArrayBuffer(binary.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binary.length; i++) {
        uint8Array[i] = binary.charCodeAt(i);
    }
    
    return new Blob([uint8Array], {type: mimeType});
};