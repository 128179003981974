<template>
  <div>
    <div class="recipes-header">
      <div class="recipes-header-left">
        <input
          type="text"
          v-model="filter"
          @keyup="onDebouncedType"
          placeholder="Filtrar recetas..."
        />
        <span class="recipes-header-total">{{
          guessPlural(totalRecipes, "receta", "recetas")
        }}</span>
        <label class="label-select" for="sortType">Ordenar por</label>
        <select
          id="sortType"
          @change="onSort"
          class="select"
          v-model="sortType"
        >
          <option :value="recipeSortTypes.BY_TITLE">Título</option>
          <option :value="recipeSortTypes.BY_TIME">Tiempo</option>
          <option :value="recipeSortTypes.BY_PEOPLE">Personas</option>
          <option :value="recipeSortTypes.BY_RATING">Estrellas</option>
          <option :value="recipeSortTypes.BY_NUMBER_OF_INGREDIENTS">
            Ingredientes
          </option>
          <option :value="recipeSortTypes.BY_NUMBER_OF_CATEGORIES">
            Categorías
          </option>
          <option :value="recipeSortTypes.BY_CREATION_DATE">
            Fecha de creación
          </option>
          <option :value="recipeSortTypes.BY_UPDATE_DATE">
            Fecha de modificación
          </option>
          <option :value="recipeSortTypes.BY_VISITED">
            Visitadas
          </option>
          <option :value="recipeSortTypes.BY_SHARED">
            Compartidas
          </option>
          <option :value="recipeSortTypes.BY_FAVORITES">
            Favoritas
          </option>
        </select>
        <select
          id="sortOrder"
          @change="onSort"
          class="select"
          v-model="sortOrder"
        >
          <option :value="sortOrders.ASC">Ascendente</option>
          <option :value="sortOrders.DESC">Descendente</option>
        </select>
      </div>
      <div>
        <Button to="/recipe/new" type="new" text="Nueva receta" />
      </div>
    </div>
    <div class="recipes-list">
      <div
        class="recipes-list-item"
        @click="onClickRecipe(recipe)"
        v-for="recipe in filteredRecipes"
        :key="recipe._id"
        :title="`Creada: ${getFormattedDate(recipe.createdAt)} ${
          recipe.createdBy || ''
        }\nModificada: ${getFormattedDate(recipe.updatedAt)} ${
          recipe.updatedBy || ''
        }`"
      >
        <div class="recipe-data">
          <div class="recipe-title">{{ recipe.title }}</div>
          <div class="recipe-info">
            <div class="recipe-info-item">
              <font-awesome-icon
                :icon="['fas', 'utensil-spoon']"
                class="icon"
              />{{ getDifficulty(recipe.difficulty) }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'clock']" class="icon" />{{
                getFormattedTime(recipe.time)
              }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'user-friends']" class="icon" />
              {{ recipe.people }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'star']" class="icon" />{{
                recipe.computedRating?.rating || "-"
              }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'hand-pointer']" class="icon" />{{
                recipe.visited
              }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'heart']" class="icon" />{{
                recipe.favorites
              }}
            </div>
            <div class="recipe-info-item">
              <font-awesome-icon :icon="['fas', 'share-alt']" class="icon" />{{
                recipe.shared
              }}
            </div>
          </div>
        </div>
        <img :src="getImageURL('recipes', recipe.image)" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import {
  getImageURL,
  getDifficulty,
  guessPlural,
  getFormattedTime,
  getFormattedDate,
} from "../util/utils";
import { RECIPE_SORT_TYPES, SORT_ORDERS } from "../util/constants";

export default {
  name: "RecipesList",
  data() {
    return {
      filteredRecipes: this.$store.state.recipes,
      filter: "",
      sortType: RECIPE_SORT_TYPES.BY_CREATION_DATE,
      sortOrder: SORT_ORDERS.DESC,
    };
  },
  methods: {
    getImageURL,
    getDifficulty,
    guessPlural,
    getFormattedTime,
    getFormattedDate,
    onType() {
      this.filteredRecipes = this.filterRecipes(this.filter);
    },
    onSort() {
      this.filteredRecipes = this.sortRecipes(
        this.filteredRecipes,
        this.sortType,
        this.sortOrder
      );
    },
    onClickRecipe(recipe) {
      this.$router.push("/recipe/" + recipe._id);
    },
  },
  computed: {
    totalRecipes() {
      return this.filteredRecipes.length;
    },
    recipeSortTypes() {
      return RECIPE_SORT_TYPES;
    },
    sortOrders() {
      return SORT_ORDERS;
    },
    ...mapState(["recipes"]),
    ...mapGetters(["filterRecipes", "sortRecipes"]),
  },
  created() {
    // Debouncing with Lodash
    this.onDebouncedType = _.debounce(this.onType, 300);
  },
  unmounted() {
    // Cancel the timer when the component is removed
    this.onDebouncedType.cancel();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.recipes-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.recipes-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.recipes-header input {
  padding: 0.5rem 1em;
  border: 1px solid #eaecef;
  font-size: 1.1rem;
  outline: none;
}
.recipes-header input::placeholder {
  color: #afafaf;
}
.recipes-header-total {
  font-size: 1.1rem;
  margin-left: 1rem;
  color: #949494;
  font-style: italic;
}
.recipes-header .select {
  margin-left: 0.5rem;
  padding: 0.5rem 1em;
  font-size: 1.1rem;
  border: 1px solid #d4d9e0;
  outline: none;
  color: #2c3e50;
}
.recipes-header .label-select {
  margin-left: 5rem;
  font-size: 1.1rem;
  color: #2c3e50;
}
.recipes-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.recipes-list-item {
  width: 19.5rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0.5rem;
}
.recipes-list-item:hover .recipe-data {
  box-shadow: 0px 0px 15px 2px #afafaf;
}
.recipes-list-item:hover img {
  box-shadow: 0px 0px 15px 2px #afafaf;
}
.recipe-data {
  padding: 0.5rem;
  box-shadow: 0px 0px 10px 1px #dcdcdc;
}
.recipes-list-item img {
  width: 100%;
  box-shadow: 0px 0px 10px 1px #dcdcdc;
}
.recipe-title {
  font-size: 1.3rem;
  font-weight: 500;
}
.recipe-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0;
  color: #4a4a4a;
  font-size: 0.7rem;
}
.recipe-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2.5rem;
}
.recipe-info .icon {
  margin-bottom: 0.2rem;
}
</style>
