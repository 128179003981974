<template>
  <div class="header">
    <div @click="onClickHome" class="header-left">
        <img alt="Cocina con lo que tienes logo" src="../assets/logo.png" />
        <span class="header-title">Cocina con lo que tienes</span>
    </div>
    <div class="header-right">
      <router-link class="option" to="/">Inicio</router-link>
      <!-- <router-link class="option" to="/search">Buscador</router-link> -->
      <router-link class="option" to="/recipes">Recetas</router-link>
      <router-link class="option" to="/recipe/new">+ Nueva</router-link>
      <router-link class="option" to="/categories">Categorías</router-link>
      <router-link class="option" to="/ingredients">Ingredientes</router-link>
      <router-link class="option" to="/config">Configuración</router-link>
      <div class="user">
        <div @click="onSignOut" class="username">
          <span class="username-value">{{ user }}</span>
          <span class="username-signout">Cerrar sesión</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  emits: ["onSignOut"],
  props: {
    user: String,
  },
  methods: {
    onSignOut() {
      this.$emit("onSignOut");
    },
    onClickHome() {
      this.$router.push("/");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  background-color: #fff;
  height: 3rem;
  padding: 0.5rem 1rem;
  box-shadow: 0px 0px 10px 1px #dedede;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
}
.header-left {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.header-left span {
  font-weight: 500;
  font-size: 1.3rem;
}
.header-left img {
  height: 100%;
  padding-right: 1rem;
}
.header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-right .option {
  padding: 0 1.5rem;
  color: #2c3e50;
  text-decoration: none;
}
.header-right .option:hover {
  color: #000;
}
.header-right .user {
  padding-left: 2rem;
  margin-left: 1.5rem;
  border-left: 1px solid #eaecef;
  width: 11rem;
}
.header-right .user {
  cursor: pointer;
}
.header-right .user .username-signout {
  display: none;
  color: #a11f1f;
}
.header-right .user:hover .username-signout {
  display: block;
}
.header-right .user:hover .username-value {
  display: none;
}
@media (max-width: 1200px) {
  .header-title {
    display: none;
  }
}
</style>
