<template>
  <div class="cropper-wrapper">
    <input
      class="upload-input"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />
    <Button
      class="upload-button"
      v-on:onClickButton="showFileChooser"
      text="Cargar imagen"
    />
    <div class="cropper-content">
      <section class="cropper-area">
        <div class="img-cropper">
          <vue-cropper
            v-show="imgSrc"
            ref="cropper"
            :aspect-ratio="16 / 9"
            :src="imgSrc"
            @cropstart="onDebouncedCrop"
            @cropend="onDebouncedCrop"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import _ from "lodash";

export default {
  components: {
    VueCropper,
  },
  emits: ["onCrop"],
  data() {
    return {
      imgSrc: null,
      cropImg: "",
    };
  },
  methods: {
    showFileChooser() {
      this.$refs.input.click();
    },
    onCrop() {
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
      const dataURL = croppedCanvas.toDataURL("image/jpeg");
      croppedCanvas.toBlob((blob) => {
        this.$emit("onCrop", {
          dataURL,
          blob,
        });
      }, "image/jpeg");
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          setTimeout(() => {
            this.onCrop();
          });
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  created() {
    // Debouncing with Lodash
    this.onDebouncedCrop = _.debounce(this.onCrop, 1000);
  },
  mounted() {
    this.showFileChooser();
  },
  unmounted() {
    // Cancel the timer when the component is removed
    this.onDebouncedCrop.cancel();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.upload-input {
  display: none;
}
.upload-button {
  margin-bottom: 1rem;
}
.cropper-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cropper-content {
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 8px 1px #ccc;
}
.cropper-area {
  width: 40rem;
  height: calc(40rem * 9 / 16);
  background-color: #efefef;
}
</style>