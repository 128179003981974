<template>
  <div>
    <div class="header">
      <div class="input-wrapper">
        <input
          class="input"
          type="text"
          v-model="ingredientSearch"
          placeholder="Buscar ingredientes..."
          v-on:keyup.enter="onSearch"
        />
        <font-awesome-icon
          class="icon"
          @click="onSearch"
          :icon="['fas', 'search']"
        />
      </div>
      <div v-if="ingredientSearch" class="filtered-ingredients">
        <ul v-if="filteredIngredients.length">
          <li
            @click="onAddIngredient(ing)"
            v-for="ing in filteredIngredients"
            :key="ing._id"
          >
            {{ ing.name }}
          </li>
        </ul>
        <div
          @click="onAddNewIngredient"
          class="no-results"
          v-if="filteredIngredients.length === 0"
        >
          No existen ingredientes para la búsqueda <b>{{ ingredientSearch }}</b>
        </div>
      </div>
      <div class="tags">
        <Tag
          v-for="(ingredient, index) in ingredients"
          :key="index"
          :type="`ingredient-${ingredient.type}`"
          :text="ingredient.name"
          closable="true"
          v-on:onCloseTag="onDeleteIngredient(index)"
        />
      </div>
    </div>
    <div class="recipes">
      <ul>
        <li v-for="(recipe, index) in search" :key="index">
          <img
            class="recipe-image"
            :src="getImageURL('recipes', recipe.image, false)"
          />
          <div class="recipe-ingredients-wrapper">
            <div class="recipe-title">{{ recipe.title }}</div>
            <div>
              Coincidentes:
              <Tag
                v-for="(ingredient, index) in intersection(recipe.ingredients)"
                :key="index"
                :type="`ingredient-${ingredient.type}`"
                :text="ingredient.name"
              />
            </div>
            <div>
              No coincidentes:
              <Tag
                v-for="(ingredient, index) in difference(recipe.ingredients)"
                :key="index"
                :type="`ingredient-${ingredient.type}`"
                :text="ingredient.name"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SEARCH_RECIPES, CLEAN_SEARCH_RECIPES } from "../store/actions";
import { getImageURL } from "../util/utils";

export default {
  name: "Search",
  data() {
    return {
      ingredientSearch: "",
      ingredients: [],
    };
  },
  computed: {
    filteredIngredients() {
      return this.filterIngredients({
        search: this.ingredientSearch,
        primary: true,
        secondary: true,
        tertiary: true,
      });
    },
    ingredientsIds() {
      return this.ingredients.map((ing) => ing._id);
    },
    ...mapState(["search"]),
    ...mapGetters(["filterIngredients", "findIngredientById"]),
  },
  methods: {
    getImageURL,
    onAddIngredient(ingredient) {
      if (this.search.length) this.cleanRecipes();
      this.ingredientSearch = "";
      this.ingredients.push(ingredient);
    },
    onDeleteIngredient(index) {
      if (this.search.length) this.cleanRecipes();
      this.ingredients.splice(index, 1);
    },
    onSearch() {
      this.$store.dispatch(SEARCH_RECIPES, { ingredients: this.ingredients });
    },
    intersection(ingredients) {
      return ingredients
        .filter((ing) => this.ingredientsIds.includes(ing))
        .map((ing) => this.findIngredientById(ing));
    },
    difference(ingredients) {
      return ingredients
        .filter((ing) => !this.ingredientsIds.includes(ing))
        .map((ing) => this.findIngredientById(ing));
    },
    cleanRecipes() {
      this.$store.dispatch(CLEAN_SEARCH_RECIPES);
    }
  },
  beforeUnmount() {
    this.cleanRecipes();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.header .input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .input {
  padding: 0.5rem 1em;
  border: 1px solid #eaecef;
  outline: none;
  font-size: 1.1rem;
  width: 25rem;
}
.header .icon {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: #8e8e8e;
  cursor: pointer;
}
.header .tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
}
.filtered-ingredients {
  background-color: #fff;
  border: 1px solid #d4d9e0;
  border-top: none;
  padding: 0 0.5rem;
  width: 25rem;
  max-height: 25rem;
  overflow-y: scroll;
  position: absolute;
  top: 2.5rem;
}
.filtered-ingredients ul {
  width: 100%;
  margin: 0;
  padding: 0;
}
.filtered-ingredients ul li {
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
}
.filtered-ingredients .no-results {
  padding: 0.5rem 0;
}
.recipes ul {
  padding: 0;
  margin: 0;
}
.recipes li {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  border-top: 1px solid #eaecef;
  margin: 0 1rem;
}
.recipe-image {
  width: 10rem;
  margin-right: 1rem;
}
.recipe-title {
  font-weight: bold;
}
</style>
