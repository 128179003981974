<template>
  <div>
    <table>
      <thead>
        <tr>
          <th class="date">Fecha</th>
          <th class="type">Tipo</th>
          <th class="value">Valor</th>
          <th class="localization">Ubicación</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stat, index) in stats" :key="index">
          <td>{{ getFormattedDate(stat.createdAt, { relative: true }) }}</td>
          <td>{{ getTypeText(stat.type) }}</td>
          <td>
            <div v-if="stat.type === statTypes.INGREDIENTS_SEARCH.value">
              <Tag
                v-for="(ingredient, index) in getIngredients(stat)"
                :key="index"
                type="ingredient"
                :text="ingredient.name"
              />
            </div>
            <div v-if="stat.type === statTypes.CATEGORY_VIEW.value">
              <Tag
                v-if="!!getCategory(stat)"
                type="category"
                :text="getCategory(stat).name"
              />
            </div>
            <div
              v-if="
                stat.type === statTypes.RECIPE_VIEW.value ||
                stat.type === statTypes.RECIPE_FAVORITE.value ||
                stat.type === statTypes.RECIPE_SHARE.value
              "
            >
              <Tag
                v-if="!!getRecipe(stat)"
                type="recipe"
                :text="getRecipe(stat).title"
              />
            </div>
          </td>
          <td>
            {{
              stat.geoData
                ? `${stat.geoData.city}, ${stat.geoData.regionName}, ${stat.geoData.country}`
                : "-"
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { STAT_TYPES } from "../util/constants";
import { getFormattedDate } from "../util/utils";

// TODO: que los tags de recetas linken a la receta

export default {
  name: "HomeStats",
  data() {
    return {
      statTypes: STAT_TYPES,
    };
  },
  methods: {
    getFormattedDate,
    getTypeText(type) {
      return Object.values(STAT_TYPES).find((stat) => stat.value === type).text;
    },
    getIngredients(stat) {
      if (!stat?.value) return [];
      return decodeURIComponent(stat.value)
        .split("/")[1]
        .split(",")
        .map((item) => this.findIngredientById(item.split(";")[0]))
        .filter((item) => !!item);
    },
    getCategory(stat) {
      return this.findCategoryById(stat.value);
    },
    getRecipe(stat) {
      return this.findRecipeById(stat.value);
    },
  },
  computed: {
    ...mapState(["stats"]),
    ...mapGetters(["findIngredientById", "findRecipeById", "findCategoryById"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.kpi {
  background-color: #fff;
  margin: 2rem;
  padding: 1rem;
  border: 1px solid #eaecef;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
}
.kpi .value {
  font-size: 3rem;
}
.kpi .title {
  font-size: 1.2rem;
}
table {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}
table thead th {
  border-bottom: 1px solid #eaecef;
  padding: 0.5rem;
}
table tbody td {
  border-bottom: 1px solid #eaecef;
  padding: 0.5rem;
}
table tbody tr:last-child {
  border-bottom: none;
}
.date {
  width: 11rem;
}
.type {
  width: 9rem;
}
.value {
  width: auto;
}
.localization {
  width: auto;
}
</style>
