import * as api from '../api/requests';
import * as m from './mutations';
import router from '../router';

import { Storage } from 'aws-amplify';

import { clone, getRatingValue, guessPlural, sortByAttrValue } from '../util/utils';
import { NOTIFICATION_TIMEOUT, NOTIFICATION_TYPES, SORT_ORDERS } from '../util/constants';

export const GET_INITIAL_DATA = 'GET_INITIAL_DATA';
export const TRIGGER_NOTIFICATION = 'TRIGGER_NOTIFICATION';
export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SAVE_RECIPE = 'SAVE_RECIPE';
export const CREATE_RECIPE = 'CREATE_RECIPE';
export const UPDATE_RECIPE = 'UPDATE_RECIPE';
export const DELETE_RECIPE = 'DELETE_RECIPE';
export const GENERATE_RECIPE = 'GENERATE_RECIPE';
export const GENERATE_RECIPE_IMAGE = 'GENERATE_RECIPE_IMAGE';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CREATE_INGREDIENT = 'CREATE_INGREDIENT';
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT';
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const RECOUNT_RECIPES = 'RECOUNT_RECIPES';
export const REFRESH_STATS = 'REFRESH_STATS';
export const SEARCH_RECIPES = 'SEARCH_RECIPES';
export const CLEAN_SEARCH_RECIPES = 'CLEAN_SEARCH_RECIPES';

const saveRecipeIngredientsAndCategories = async (recipeToSave, dispatch, commit, getters) => {
    const ingredientsToCreate = recipeToSave.ingredients.filter(ing => ing.isNew);

    if (ingredientsToCreate.length) {
        await Promise.all(ingredientsToCreate.map(async ing =>
            await dispatch(CREATE_INGREDIENT, {
                ingredient: {
                    ...ing,
                    type: parseInt(ing.type),
                },
            }),
        ));

        commit(m.SET_LOADING);

        const newIngredients = ingredientsToCreate.map(ing => getters.findIngredientByName(ing.name));
        recipeToSave.ingredients = [
            ...recipeToSave.ingredients.filter(ing => !ing.isNew).map(ing => ing._id),
            ...newIngredients.map(ing => ing._id),
        ];
    } else {
        recipeToSave.ingredients = recipeToSave.ingredients.map(ing => ing._id);
    }

    const categoriesToCreate = recipeToSave.categories.filter(cat => cat.isNew);

    if (categoriesToCreate.length) {
        await Promise.all(categoriesToCreate.map(async category => {
            await dispatch(CREATE_CATEGORY, { category });
            try {
                await Storage.put('categories/' + category.image, category.categoryImageData.blob, {
                    contentType: 'image/jpg',
                    customPrefix: {
                        public: '',
                    },
                });
            } catch (error) {
                dispatch(SHOW_ERROR, `Error al subir imagen de categoría '${category.name}': ${error}`);
            }
        }));

        commit(m.SET_LOADING);

        const newCategories = categoriesToCreate.map(cat => getters.findCategoryByName(cat.name));
        recipeToSave.categories = [
            ...recipeToSave.categories.filter(cat => !cat.isNew).map(cat => cat._id),
            ...newCategories.map(cat => cat._id),
        ];
    } else {
        recipeToSave.categories = recipeToSave.categories.map(cat => cat._id);
    }

    recipeToSave.ingredientsNumber = recipeToSave.ingredients.length;
    recipeToSave.recipe = recipeToSave.recipe.filter(rec => !!rec.trim());

    const recipe = await (recipeToSave._id ?
        api.put("recipes/" + recipeToSave._id, recipeToSave) :
        api.post("recipes", recipeToSave));

    if (!recipe.error) {
        recipe.ingredients = recipe.ingredients.map(ing => getters.findIngredientById(ing));
        recipe.categories = recipe.categories.map(cat => getters.findCategoryById(cat));
    }

    return recipe;
};

export default {
    async [GET_INITIAL_DATA]({ commit, getters }) {
        commit(m.SET_LOADING);

        const data = await Promise.all([
            api.get("recipes?limit=3000"),
            api.get("ingredients"),
            api.get("categories"),
            api.get("config"),
            api.get("stats?limit=100"),
        ]);

        const recipes = data[0].map(recipe => ({
            ...recipe,
            computedRating: getRatingValue(recipe.ratings),
        })).sort(sortByAttrValue('createdAt', SORT_ORDERS.DESC));
        commit(m.SET_RECIPES, { recipes });

        const authors = getters.getAuthors().sort();
        commit(m.SET_AUTHORS, { authors });

        const ingredients = data[1].map(ingredient => ({
            ...ingredient,
            recipes: getters.filterRecipesByIngredient(ingredient._id),
        })).sort(sortByAttrValue('createdAt', SORT_ORDERS.DESC));
        commit(m.SET_INGREDIENTS, { ingredients });

        const ingredientGroups = getters.getIngredientGroups().sort();
        commit(m.SET_INGREDIENT_GROUPS, { ingredientGroups });

        const categories = data[2].map(category => ({
            ...category,
            recipes: getters.filterRecipesByCategory(category._id),
        })).sort(sortByAttrValue('name'));
        commit(m.SET_CATEGORIES, { categories });

        const config = data[3][0];
        commit(m.SET_CONFIG, { config });

        const stats = data[4];
        commit(m.SET_STATS, { stats });

        commit(m.SET_APP_LOADED);

        commit(m.CLEAR_LOADING);
    },
    [SHOW_ERROR]({ dispatch }, payload) {
        dispatch(TRIGGER_NOTIFICATION, {
            notificationType: NOTIFICATION_TYPES.ERROR,
            notificationMessage: payload,
        });
    },
    [SHOW_SUCCESS]({ dispatch }, payload) {
        dispatch(TRIGGER_NOTIFICATION, {
            notificationType: NOTIFICATION_TYPES.SUCCESS,
            notificationMessage: payload,
        });
    },
    [TRIGGER_NOTIFICATION]({ commit }, payload) {
        commit(m.ADD_NOTIFICATION, payload);
        setTimeout(() => {
            commit(m.CLEAR_NOTIFICATION);
        }, NOTIFICATION_TIMEOUT);
    },
    async [CREATE_RECIPE]({ state, commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const { recipe: recipeToCreate, recipeImageData } = payload;

        const recipe = await saveRecipeIngredientsAndCategories(recipeToCreate, dispatch, commit, getters);

        if (recipe.error) {
            dispatch(SHOW_ERROR, recipe.error);
        } else {
            try {
                await Storage.put('recipes/' + recipe.image, recipeImageData.blob, {
                    contentType: 'image/jpg',
                    customPrefix: {
                        public: '',
                    },
                });
            } catch (error) {
                dispatch(SHOW_ERROR, `Error al subir imagen de receta: ${error}`);
            }

            commit(m.ADD_RECIPE, { recipe });

            if (recipe.author && !state.authors.includes(recipe.author)) {
                commit(m.ADD_AUTHOR, { author: recipe.author });
            }

            await dispatch(RECOUNT_RECIPES, {
                ingredients: clone(recipe.ingredients),
                categories: clone(recipe.categories),
            });

            dispatch(SHOW_SUCCESS, `Receta '${recipe.title}' creada correctamente`);

            router.push('/recipe/' + recipe._id);
        }

        commit(m.CLEAR_LOADING);
    },
    async[UPDATE_RECIPE]({ state, commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const { recipe: recipeToUpdate, recipeImageData } = payload;

        const recipe = await saveRecipeIngredientsAndCategories(recipeToUpdate, dispatch, commit, getters);

        if (recipe.error) {
            dispatch(SHOW_ERROR, recipe.error);
        } else {
            const originalRecipe = getters.findRecipeById(recipe._id);

            // Si hemos definido una nueva imagen borramos la anterior y subimos la nueva
            if (recipeImageData) {
                try {
                    await Storage.remove('recipes/' + originalRecipe.image, {
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
                }
                try {
                    await Storage.put('recipes/' + recipe.image, recipeImageData.blob, {
                        contentType: 'image/jpg',
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al subir imagen: ${error}`);
                }
            }

            // Si no hemos definido una nueva imagen pero hemos cambiado el
            // título cambia también el nombre de la imagen, por lo que necesitamos
            // descargar la imagen con el nombre original, borrarla y volver a
            // cargarla con el nuevo nombre
            if (!recipeImageData && originalRecipe.image !== recipe.image) {
                let originalFileImage;
                try {
                    originalFileImage = await Storage.get('recipes/' + originalRecipe.image, {
                        download: true,
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al descargar imagen: ${error}`);
                }
                try {
                    await Storage.remove('recipes/' + originalRecipe.image, {
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
                }
                try {
                    await Storage.put('recipes/' + recipe.image, originalFileImage.Body, {
                        contentType: 'image/jpg',
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al subir imagen: ${error}`);
                }
            }

            commit(m.UPDATE_RECIPE, { recipe })

            if (recipe.author && !state.authors.includes(recipe.author)) {
                commit(m.ADD_AUTHOR, { author: recipe.author });
            } else if (!recipe.author) {
                const authors = getters.getAuthors();
                commit(m.SET_AUTHORS, { authors });
            }

            await dispatch(RECOUNT_RECIPES, {
                ingredients: clone(recipe.ingredients),
                categories: clone(recipe.categories),
            });

            dispatch(SHOW_SUCCESS, `Receta '${recipe.title}' modificada correctamente`);

            router.push('/recipe/' + recipe._id);
        }

        commit(m.CLEAR_LOADING);
    },
    async[DELETE_RECIPE]({ commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const recipe = await api.del("recipes/" + payload.recipe._id);

        if (recipe.error) {
            dispatch(SHOW_ERROR, recipe.error);
        } else {
            try {
                await Storage.remove('recipes/' + recipe.image, {
                    customPrefix: {
                        public: '',
                    },
                });
            } catch (error) {
                dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
            }

            commit(m.DELETE_RECIPE, { recipe });

            if (recipe.author) {
                const authors = getters.getAuthors();
                commit(m.SET_AUTHORS, { authors });
            }

            await dispatch(RECOUNT_RECIPES, {
                ingredients: clone(payload.recipe.ingredients),
                categories: clone(payload.recipe.categories),
            });

            dispatch(SHOW_SUCCESS, `Receta '${recipe.title}' eliminada correctamente`);

            router.push('/recipes');
        }

        commit(m.CLEAR_LOADING);
    },
    async[GENERATE_RECIPE]({ commit, dispatch }) {
        commit(m.SET_LOADING);

        const recipe = await api.get("recipes/generate");

        if (recipe.error) return dispatch(SHOW_ERROR, recipe.error);

        commit(m.CLEAR_LOADING);

        return recipe;
    },
    async[GENERATE_RECIPE_IMAGE]({ commit, dispatch }, payload) {
        commit(m.SET_LOADING);

        const recipeImage = await api.post("recipes/generateImage", { recipeInfo: payload.recipeInfo });

        if (recipeImage.error) return dispatch(SHOW_ERROR, recipeImage.error);

        commit(m.CLEAR_LOADING);

        return recipeImage;
    },
    async[CREATE_CATEGORY]({ commit, dispatch }, payload) {
        commit(m.SET_LOADING);

        const category = await api.post("categories", payload.category);

        if (category.error) {
            dispatch(SHOW_ERROR, category.error);
        } else {
            commit(m.ADD_CATEGORY, { category })
        }

        commit(m.CLEAR_LOADING);
    },
    async[UPDATE_CATEGORY]({ getters, commit, dispatch }, payload) {
        commit(m.SET_LOADING);

        const category = await api.put("categories/" + payload.category._id, payload.category);

        if (category.error) {
            dispatch(SHOW_ERROR, category.error);
        } else {
            const originalCategory = getters.findCategoryById(category._id);

            if (payload.imageData) {
                try {
                    await Storage.remove('categories/' + originalCategory.image, {
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
                }
                try {
                    await Storage.put('categories/' + category.image, payload.imageData.blob, {
                        contentType: 'image/jpg',
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al subir imagen: ${error}`);
                }
            }

            // Si no hemos definido una nueva imagen pero hemos cambiado el
            // nombre cambia también el nombre de la imagen, por lo que necesitamos
            // descargar la imagen con el nombre original, borrarla y volver a
            // cargarla con el nuevo nombre
            if (!payload.imageData && originalCategory.image !== category.image) {
                let originalFileImage;
                try {
                    originalFileImage = await Storage.get('categories/' + originalCategory.image, {
                        download: true,
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al descargar imagen: ${error}`);
                }
                try {
                    await Storage.remove('categories/' + originalCategory.image, {
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
                }
                try {
                    await Storage.put('categories/' + category.image, originalFileImage.Body, {
                        contentType: 'image/jpg',
                        customPrefix: {
                            public: '',
                        },
                    });
                } catch (error) {
                    dispatch(SHOW_ERROR, `Error al subir imagen: ${error}`);
                }
            }

            commit(m.UPDATE_CATEGORY, { category });

            await dispatch(RECOUNT_RECIPES, { categories: [clone(category)] });

            dispatch(SHOW_SUCCESS, `Categoría '${category.name}' modificada correctamente`);
        }

        commit(m.CLEAR_LOADING);
    },
    async[DELETE_CATEGORY]({ commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const recipesWithCategory = getters.filterRecipesByCategory(payload.category._id);

        if (recipesWithCategory.length) {
            commit(m.CLEAR_LOADING);

            return dispatch(SHOW_ERROR, `No puedes eliminar la categoría '${payload.category.name}' porque se encuentra presente en ${guessPlural(recipesWithCategory.length, "receta", "recetas")}`);
        }

        const category = await api.del("categories/" + payload.category._id);

        if (category.error) {
            dispatch(SHOW_ERROR, category.error);
        } else {
            try {
                await Storage.remove('categories/' + category.image, {
                    customPrefix: {
                        public: '',
                    },
                });
            } catch (error) {
                dispatch(SHOW_ERROR, `Error al borrar imagen: ${error}`);
            }

            commit(m.DELETE_CATEGORY, { category });

            dispatch(SHOW_SUCCESS, `Categoría '${category.name}' eliminada correctamente`);
        }

        commit(m.CLEAR_LOADING);
    },
    async[CREATE_INGREDIENT]({ commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const ingredient = await api.post("ingredients", payload.ingredient);

        if (ingredient.error) {
            dispatch(SHOW_ERROR, ingredient.error);
        } else {
            commit(m.ADD_INGREDIENT, { ingredient });

            const ingredientGroups = getters.getIngredientGroups();
            commit(m.SET_INGREDIENT_GROUPS, { ingredientGroups });
        }

        commit(m.CLEAR_LOADING);
    },
    async[UPDATE_INGREDIENT]({ commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const ingredient = await api.put("ingredients/" + payload.ingredient._id, payload.ingredient);

        if (ingredient.error) {
            dispatch(SHOW_ERROR, ingredient.error);
        } else {
            commit(m.UPDATE_INGREDIENT, { ingredient });

            const ingredientGroups = getters.getIngredientGroups();
            commit(m.SET_INGREDIENT_GROUPS, { ingredientGroups });

            await dispatch(RECOUNT_RECIPES, { ingredients: [clone(ingredient)] });

            dispatch(SHOW_SUCCESS, `Ingrediente '${ingredient.name}' modificado correctamente`);
        }

        commit(m.CLEAR_LOADING);
    },
    async[DELETE_INGREDIENT]({ commit, dispatch, getters }, payload) {
        commit(m.SET_LOADING);

        const recipesWithIngredient = getters.filterRecipesByIngredient(payload.ingredient._id);

        if (recipesWithIngredient.length) {
            commit(m.CLEAR_LOADING);

            return dispatch(SHOW_ERROR, `No puedes eliminar el ingrediente '${payload.ingredient.name}' porque se encuentra presente en ${guessPlural(recipesWithIngredient.length, "receta", "recetas")}`);
        }

        const ingredient = await api.del("ingredients/" + payload.ingredient._id);

        if (ingredient.error) {
            dispatch(SHOW_ERROR, ingredient.error);
        } else {
            commit(m.DELETE_INGREDIENT, { ingredient });

            const ingredientGroups = getters.getIngredientGroups();
            commit(m.SET_INGREDIENT_GROUPS, { ingredientGroups });

            dispatch(SHOW_SUCCESS, `Ingrediente '${ingredient.name}' eliminado correctamente`);
        }

        commit(m.CLEAR_LOADING);
    },
    async[UPDATE_CONFIG]({ commit, dispatch }, payload) {
        commit(m.SET_LOADING);

        const config = await api.put("config/" + payload.config._id, payload.config);

        if (config.error) {
            dispatch(SHOW_ERROR, config.error);
        } else {
            commit(m.SET_CONFIG, { config });

            dispatch(SHOW_SUCCESS, `Configuración modificada correctamente`);
        }

        commit(m.CLEAR_LOADING);
    },
    [RECOUNT_RECIPES]({ commit, getters }, payload) {
        const { ingredients = [], categories = [] } = payload;

        ingredients.forEach(ingredient => {
            ingredient.recipes = getters.filterRecipesByIngredient(ingredient._id);
            commit(m.UPDATE_INGREDIENT, { ingredient });
        });

        categories.forEach(category => {
            category.recipes = getters.filterRecipesByCategory(category._id);
            commit(m.UPDATE_CATEGORY, { category });
        });
    },
    async [REFRESH_STATS]({ commit }) {
        commit(m.SET_LOADING);

        const stats = await api.get("stats?limit=100");

        commit(m.SET_STATS, { stats });

        commit(m.CLEAR_LOADING);
    },
    async [SEARCH_RECIPES]({ commit, dispatch }, payload) {
        commit(m.SET_LOADING);

        const { ingredients } = payload;

        if (ingredients.length) {
            const ingredientsQueryString = encodeURIComponent(
                ingredients
                    .map(ing => `${ing._id};${ing.type};${ing.group || ''}`)
                    .join(','));

            const search = await api.get(`recipes?page=0&limit=3000&ingredients=${ingredientsQueryString}`);

            commit(m.SET_SEARCH_RESULTS, { search });
        } else {
            dispatch(SHOW_ERROR, `Debes añadir algún ingrediente`);
        }

        commit(m.CLEAR_LOADING);
    },
    [CLEAN_SEARCH_RECIPES]({ commit }) {
        commit(m.SET_SEARCH_RESULTS, { search: [] });
    },
};