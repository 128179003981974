<template>
  <amplify-authenticator>
    <amplify-sign-in
      slot="sign-in"
      username-alias="email"
      :hideSignUp="true"
    ></amplify-sign-in>
    <amplify-forgot-password
      slot="forgot-password"
      username-alias="email"
    ></amplify-forgot-password>
  </amplify-authenticator>
</template>

<script>
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

I18n.putVocabulariesForLanguage("es", {
  [Translations.SIGN_IN_HEADER_TEXT]: "Cocina con lo que tienes",
  [Translations.SIGN_IN_ACTION]: "Entrar",
  [Translations.BACK_TO_SIGN_IN]: "Volver a acceso",
  [Translations.SEND_CODE]: "Enviar código",
  [Translations.EMAIL_LABEL]: "Email",
  [Translations.EMAIL_PLACEHOLDER]: "Introduce tu email",
  [Translations.PASSWORD_LABEL]: "Contraseña",
  [Translations.PASSWORD_PLACEHOLDER]: "Introduce tu contraseña",
  [Translations.FORGOT_PASSWORD_TEXT]: "¿Has olvidado tu contraseña?",
  [Translations.RESET_PASSWORD_TEXT]: "Recuperar contraseña",
  [Translations.RESET_YOUR_PASSWORD]: "Recuperar contraseña",
  [Translations.CODE_LABEL]: "Código de verificación",
  [Translations.CODE_PLACEHOLDER]: "Introducir código",
  [Translations.NEW_PASSWORD_LABEL]: "Nueva contraseña",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Introducir nueva contraseña",
  [Translations.SUBMIT]: "Enviar",
  // TODO: Falta el literal 'Change Password' que aparece al cambiar contraseña
});
I18n.setLanguage("es");

export default {
  name: "SignIn",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root {
  --amplify-primary-color: #a11f1f;
  --amplify-primary-shade: #a11f1f;
  --amplify-primary-tint: #a11f1f;
}
</style>
