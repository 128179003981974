<template>
  <Popup v-on:onClosePopup="onClose" v-bind:headerText="headerText">
    <template v-slot:body>
      <div class="popup-body">
        <font-awesome-icon
          :icon="['fas', 'exclamation-triangle']"
          class="popup-icon"
        />
        <span>{{ bodyText }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        class="popup-button"
        v-on:onClickButton="onClose"
        text="Cancelar"
      />
      <Button
        class="popup-button"
        type="delete"
        v-on:onClickButton="onDelete"
        text="Eliminar"
      />
    </template>
  </Popup>
</template>

<script>
export default {
  name: "PopupDelete",
  props: {
    headerText: String,
    bodyText: String,
  },
  emits: ["onClosePopup", "onDelete"],
  methods: {
    onClose() {
      this.$emit("onClosePopup");
    },
    onDelete() {
      this.$emit("onDelete");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem 2rem;
}
.popup-icon {
  font-size: 3rem;
  color: rgb(179, 52, 52);
  margin-bottom: 1rem;
}
.popup-button {
  margin-left: 1rem;
}
</style>