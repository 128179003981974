import { INGREDIENT_TYPES, INGREDIENT_SORT_TYPES, CATEGORY_SORT_TYPES, RECIPE_SORT_TYPES } from "../util/constants";
import { normalizeString, sortByAttrValue, sortBySubAttrValue, sortByAttrLength } from "../util/utils";
import jaroWinkler from '../util/jaroWinkler';

export default {
    filterIngredients: state => ({ search, primary, secondary, tertiary, group }) => {
        let filtered = state.ingredients;
        if (search) filtered = filtered.filter(ingredient => normalizeString(ingredient.name).includes(normalizeString(search)));
        if (!primary) filtered = filtered.filter(ingredient => ingredient.type !== INGREDIENT_TYPES.PRIMARY);
        if (!secondary) filtered = filtered.filter(ingredient => ingredient.type !== INGREDIENT_TYPES.SECONDARY);
        if (!tertiary) filtered = filtered.filter(ingredient => ingredient.type !== INGREDIENT_TYPES.TERTIARY);
        if (group) filtered = filtered.filter(ingredient => ingredient.group);
        return filtered;
    },
    findIngredientById: state => id => {
        return state.ingredients.find(ingredient => ingredient._id === id);
    },
    findIngredientByName: state => name => {
        return state.ingredients.find(ingredient => ingredient.name === name);
    },
    sortIngredients: () => (ingredients, sortType, sortOrder) => {
        switch (sortType) {
            case INGREDIENT_SORT_TYPES.BY_NAME:
                return ingredients.sort(sortByAttrValue('name', sortOrder));
            case INGREDIENT_SORT_TYPES.BY_NUMBER_OF_RECIPES:
                return ingredients.sort(sortByAttrLength('recipes', sortOrder));
            case INGREDIENT_SORT_TYPES.BY_CREATION_DATE:
                return ingredients.sort(sortByAttrValue('createdAt', sortOrder));
            case INGREDIENT_SORT_TYPES.BY_UPDATE_DATE:
                return ingredients.sort(sortByAttrValue('updatedAt', sortOrder));
            case INGREDIENT_SORT_TYPES.BY_GROUP:
                return ingredients.sort(sortByAttrValue('group', sortOrder));
            default:
                return ingredients;
        }
    },
    getIngredientGroups: state => () => {
        return state.ingredients.reduce((prev, curr) => {
            if (curr.group && !prev.includes(curr.group)) prev.push(curr.group);
            return prev;
        }, []);
    },
    filterIngredientGroups: state => search => {
        return state.ingredientGroups.filter(group => normalizeString(group).includes(normalizeString(search)));
    },
    filterCategories: state => search => {
        return state.categories.filter(category => normalizeString(category.name).includes(normalizeString(search)));
    },
    sortCategories: () => (categories, sortType, sortOrder) => {
        switch (sortType) {
            case CATEGORY_SORT_TYPES.BY_NAME:
                return categories.sort(sortByAttrValue('name', sortOrder));
            case CATEGORY_SORT_TYPES.BY_NUMBER_OF_RECIPES:
                return categories.sort(sortByAttrLength('recipes', sortOrder));
            case CATEGORY_SORT_TYPES.BY_CREATION_DATE:
                return categories.sort(sortByAttrValue('createdAt', sortOrder));
            case CATEGORY_SORT_TYPES.BY_UPDATE_DATE:
                return categories.sort(sortByAttrValue('updatedAt', sortOrder));
            case CATEGORY_SORT_TYPES.BY_VISITED:
                return categories.sort(sortByAttrValue('visited', sortOrder));
            default:
                return categories;
        }
    },
    findCategoryById: state => id => {
        return state.categories.find(category => category._id === id);
    },
    findCategoryByName: state => name => {
        return state.categories.find(category => category.name === name);
    },
    filterRecipes: state => search => {
        return state.recipes.filter(recipe => normalizeString(recipe.title).includes(normalizeString(search)));
    },
    findRecipeById: state => id => {
        const recipe = state.recipes.find(recipe => recipe._id === id);
        if (!recipe) return;
        recipe.ingredients.forEach(ing => {
            if (ing.recipes) delete (ing.recipes);
        });
        recipe.categories.forEach(cat => {
            if (cat.recipes) delete (cat.recipes);
        });
        return recipe;
    },
    findRecipeByTitle: state => title => {
        return state.recipes.find(recipe => normalizeString(recipe.title) === normalizeString(title));
    },
    jaroWinklerRecipeTitles: state => title => {
        return state.recipes
            .reduce((similarRecipes, recipe) => {
                const titleToCompare = recipe.title;
                let distance = jaroWinkler(title, titleToCompare);
                distance = (Math.round(distance * 10000) / 10000).toFixed(4);
                if (distance > 0.75) similarRecipes.push({ title: recipe.title, distance });
                return similarRecipes;
            }, [])
            .sort((a, b) => b.distance - a.distance)
            .slice(0, 10);
    },
    filterRecipesByIngredient: state => id => {
        return state.recipes.filter(recipe => recipe.ingredients.find(ingredient => ingredient._id === id));
    },
    filterRecipesByCategory: state => id => {
        return state.recipes.filter(recipe => recipe.categories.find(category => category._id === id));
    },
    sortRecipes: () => (recipes, sortType, sortOrder) => {
        switch (sortType) {
            case RECIPE_SORT_TYPES.BY_TITLE:
                return recipes.sort(sortByAttrValue('title', sortOrder, '-'));
            case RECIPE_SORT_TYPES.BY_TIME:
                return recipes.sort(sortByAttrValue('time', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_PEOPLE:
                return recipes.sort(sortByAttrValue('people', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_RATING:
                return recipes.sort(sortBySubAttrValue('computedRating', 'rating', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_NUMBER_OF_INGREDIENTS:
                return recipes.sort(sortByAttrLength('ingredients', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_NUMBER_OF_CATEGORIES:
                return recipes.sort(sortByAttrLength('categories', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_CREATION_DATE:
                return recipes.sort(sortByAttrValue('createdAt', sortOrder, '-'));
            case RECIPE_SORT_TYPES.BY_UPDATE_DATE:
                return recipes.sort(sortByAttrValue('updatedAt', sortOrder, '-'));
            case RECIPE_SORT_TYPES.BY_VISITED:
                return recipes.sort(sortByAttrValue('visited', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_SHARED:
                return recipes.sort(sortByAttrValue('shared', sortOrder, 0));
            case RECIPE_SORT_TYPES.BY_FAVORITES:
                return recipes.sort(sortByAttrValue('favorites', sortOrder, 0));
            default:
                return recipes;
        }
    },
    getAuthors: state => () => {
        return state.recipes.reduce((prev, curr) => {
            if (curr.author && !prev.includes(curr.author)) prev.push(curr.author);
            return prev;
        }, []);
    },
    filterAuthors: state => search => {
        return state.authors.filter(author => normalizeString(author).includes(normalizeString(search)));
    },
};