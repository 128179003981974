import { API_URL } from "../util/constants";

const headers = () => ({
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem('idToken'),
});

const apiCall = (method, path, data) => {
    return fetch(API_URL[process.env.NODE_ENV] + path, {
        method,
        headers: headers(),
        ...data && { body: JSON.stringify(data) },
    })
        .then(response => response.json())
        .catch(error => { error });
};

export const get = (path) => apiCall('GET', path)

export const put = (path, data) => apiCall('PUT', path, data);

export const post = (path, data) => apiCall('POST', path, data);

export const del = (path) => apiCall('DELETE', path);