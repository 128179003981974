<template>
  <span class="tag" :class="type">
    {{ text }}
    <font-awesome-icon
      v-if="closable === 'true'"
      class="icon"
      @click="onClick"
      :icon="['fas', 'times']"
    />
  </span>
</template>

<script>
export default {
  name: "Tag",
  emits: ["onCloseTag"],
  props: {
    type: String,
    text: String,
    closable: String,
  },
  methods: {
    onClick() {
      this.$emit("onCloseTag");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tag {
  margin: 0.3rem 0.8rem 0.3rem 0;
  padding: 0.2rem 0.5rem;
  color: #fff;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 0.1rem;
  display: inline-block;
}
.tag.ingredient {
  background-color: #4e78e1;
}
.tag.generated-ingredient {
  background-color: #9734ba;
}
.tag.ingredient-1 {
  background-color: #c53d3d;
}
.tag.ingredient-2 {
  background-color: #ff9a9a;
}
.tag.ingredient-3 {
  background-color: #c7c7c7;
}
.tag.category {
  background-color: #5a9456;
}
.tag.generated-category {
  background-color: #9734ba;
}
.tag.recipe {
  background-color: #9a7257;
}
.tag .icon {
  cursor: pointer;
  margin-left: 0.3rem;
}
</style>
